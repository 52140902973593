import React, { useState, useContext, useEffect } from "react";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { PageHeader, Button, Tabs, Input, Empty, Space, Row, Col, notification } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {
    GET_WINE_TYPES,
    CREATE_WINE,
} from "./gql_Wines";

import { useLazyQuery, useMutation } from "@apollo/client";
import Wine from "./Wine";

const { TabPane } = Tabs;
const { Search } = Input;

const Wines = () => {

    const [esta, setEsta] = useState('')
    const [selecTab, setSelecTab] = useState('')
    const [activeKey, setActiveKey] = useState('');
    const [panes, setPanes] = useState([
        { title: '...', content: 'chargement en cours...', key: '1' },
    ]);

    const { general, handleSetGeneral, showMenu, menuVisible } = useContext(GeneralContext)
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext)

    const [getWineTypes, { error: error_gd, data: data_gd }] = useLazyQuery(GET_WINE_TYPES, {
        onError: error_gd => console.log("error_gd", JSON.stringify(error_gd)),
        onCompleted: data_gd => generatePanesFromData(data_gd),
        fetchPolicy: "no-cache",
    });

    const [createWine, { error: error_cd, data: data_cd }] = useMutation(CREATE_WINE, {
        onError: (error_cd) => console.log("error_cd :: ", JSON.stringify(error_cd)),
        onCompleted: (data_cd) => {
            notify('success');
            return getWineTypes({ variables: { establishments: establishment.id } })
        },
        fetchPolicy: "no-cache",
    });

    const notify = (type) => {
        notification[type]({
            message: "Mise à jour réussie",
        });
    };

    useEffect(() => {
        handleSetGeneral({ ...general, page: "/vins" });

        if (establishment) {
            getWineTypes({ variables: { establishments: establishment.id } });
            setEsta(`- ${establishment.Name} -`)
        }

        if (menuVisible === true) {
            showMenu()
        }

    }, []);

    const generatePanesFromData = (datas) => {
        const newPanes = datas.wineTypes.map((pane) => {
            return {
                title: pane.name,
                content: pane.wines.length > 0
                    ? pane.wines.map((wine, i) => {
                        return <Wine
                            wine={wine}
                            types={datas.wineTypes}
                            type={pane.name}
                            typeID={pane.id}
                            getWineTypes={getWineTypes}
                            key={i}
                        />
                    })
                    : null
                ,
                key: pane.id,
            }
        })
        newPanes.sort((a, b) => a.key - b.key)
        setPanes(newPanes)

        if (!activeKey) {
            if (newPanes.length > 0) {
                setActiveKey(newPanes[0].key);
            }
        }
    }

    const handleChangeTab = (e) => {
        setActiveKey(e)
    }

    const handleCreateWine = () => {
        const newWine = {
            name: 'nom',
            // description: 'décrivez ce vin...',
            wine_types: activeKey,
            active: true,
            country: '',
            year: undefined,
            region: '',
            designation: '',
            producer: '',
            specs: [{
                designation: "Bouteille",
                size: 75,
                price: 35,
                priceHH: 15
            }]
        }
        createWine({ variables: newWine })
    }

    if (!establishment) {
        return <Empty className="emptyty" description="sélectionner un restaurant" />;
    }

    return (

        <div style={{ backgroundColor: "white" }}>
            <PageHeader
                style={{ padding: "0 0 24px 0" }}
                className="site-page-header"
                title="Les Vins & Champagnes"
                subTitle="de votre restaurant"
            />
            <Space style={{ marginBottom: 16 }}>
                <Button
                    onClick={() => handleCreateWine()}
                    type="primary"
                    style={{}}
                >
                    Ajouter une référence
                </Button>

            </Space>

            <Tabs
                type="card"
                animated={false}
                onChange={(e) => handleChangeTab(e)}
            >
                {panes.map((pane) => (
                    <TabPane
                        tab={pane.title}
                        key={pane.key}
                        id={pane.key}
                    >
                        {pane.content}
                    </TabPane>
                ))}
            </Tabs>
            <div style={{
                color: "#888888",
                padding: "1em 0",
                margin: "0",
                width: "100%"
            }}
                align='center'>
                {esta}
            </div>
        </div>
    )
};

export default Wines;
