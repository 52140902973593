import React, { useContext, useEffect, useState } from "react";
import { Space, PageHeader, Button, Tabs, Input, Empty } from "antd";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { GET_MENUS, CREATE_MENU } from "./gql_Menus";
import { useLazyQuery, useMutation } from "@apollo/client";
import OneMenu from "./OneMenu";

const Menus = () => {

    const [esta, setEsta] = useState('')

    const { establishment } = useContext(EstablishmentContext)
    const { general, handleSetGeneral, showMenu, menuVisible } = useContext(GeneralContext)

    const [allMenus, setAllMenus] = useState([])
    const [plural, setPlural] = useState(false)

    const [menus, { error: error_m, data: data_m }] = useLazyQuery(GET_MENUS, {
        onError: (error_m) => console.log("error_m", JSON.stringify(error_m)),
        onCompleted: (data_m) => listMenus(data_m),
        fetchPolicy: "no-cache",
    });

    const [createMenu, { error: error_cm, data: data_cm }] = useMutation(CREATE_MENU, {
        onError: (error_cm) => console.log("error_cm :: ", JSON.stringify(error_cm)),
        onCompleted: (data_cm) => menus({ variables: { establishment: establishment.id } }),
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        handleSetGeneral({ ...general, page: "/formules" });

        if (establishment) {
            menus({ variables: { establishment: establishment.id } })
            setEsta(`- ${establishment.Name} -`)
        }

        if (menuVisible === true) {
            showMenu()
        }

    }, []);

    const listMenus = (datas) => {
        if (datas.menus.length > 1) {
            setPlural(true);
        }
        const newKidsOnTheBlock = datas.menus.map(m => m)
        newKidsOnTheBlock.sort((a, b) => a.id - b.id)
        setAllMenus(newKidsOnTheBlock)
    }

    const handleCreateMenu = () => {
        console.log(":: handleCreateMenu ::")
        const newMenu = {
            name: 'Formule',
            description: '',
            price: '0',
            dishes: [],
            drinks: [],
            wines: [],
            establishment: establishment.id,
        };
        console.log("newMenu :: ", newMenu)
        createMenu({ variables: newMenu })
    }

    if (!establishment) {
        return <Empty className="emptyty" description="sélectionner un restaurant" />
    }

    return (
        <div>
            <PageHeader
                style={{ padding: "0 0 24px 0" }}
                className="site-page-header"
                title={plural ? "Les Formules" : "La Formule"}
                subTitle="de votre restaurant"
            />
            <Button
                onClick={() => handleCreateMenu()}
                type="primary"
                style={{ marginBottom: 16 }}
            >
                Ajouter une formule
            </Button>

            {
                allMenus.length > 0
                    ? allMenus.map((menu, i) => <OneMenu key={i} menu={menu} listMenus={listMenus} />)
                    : null
            }
            <div style={{
                color: "#888888",
                padding: "1em 0",
                margin: "0",
                width: "100%"
            }}
                align='center'>
                {esta}
            </div>
        </div>
    )
};

export default Menus;
