import React, { useContext } from "react";
import { allergenes } from "../Dishes/Allergenes";
import { SERVER_URL } from "../../constants";
import { Image, Button, Space, Row, Col } from "antd";
import { InfoCircleOutlined, DeleteFilled } from "@ant-design/icons";
import { GeneralContext } from "../../Contexts/GeneralContext";

const Dish = ({ dish, handleDeleteDish }) => {

    const { general } = useContext(GeneralContext);
    const lang = general.langue ? general.langue : 'fr';

    const getIngredients = () => {
        if (dish.Ingredients === null || dish.Ingredients === "") {
            return
        }
        let localIngredients = [];
        Object.entries(JSON.parse(dish.Ingredients)).map((ing) => {
            if (ing[0] === lang) {
                localIngredients.push(ing[1])
            }

        })
        return localIngredients
    }

    const getAllergenes = () => {
        let titre;
        let children = [];
        const lesAllergenes = [];
        for (let key in Object.values(allergenes)) {
            lesAllergenes.push(Object.values(allergenes)[key]);
            lesAllergenes.forEach((el) => {
                if (Object.keys(el)[0] === lang) {
                    titre = Object.keys(Object.values(el)[0])[0];
                    const tousAllergenes = Object.values(Object.values(el)[0])[0];
                    children = [...tousAllergenes]
                }
            });
        }

        const dishChildren = dish.allergenes !== null
            ? dish.allergenes.split(',').map(d => children[d])
            : null

        const formatTitle = (<span className='allerTitleInDishInCard'><InfoCircleOutlined /> {`${titre}: `}</span>);
        const formatChildren = dishChildren ? dishChildren.join(', ') : undefined
        return dish.allergenes !== null ? [formatTitle, formatChildren] : undefined
    }

    //////////////// return ////
    //////////////// return ////

    return dish.active
        ? (
            <Space
                className="dishinDish"
                direction="vertical"
                style={{
                    width: "100%",
                    margin: "1em 0"
                }}
            >
                <Row 
                    className="in-dish-00"
                    style={{
                    padding: "0",
                    margin: "0",
                    width: "100%"
                }}>
                    <Col span="16">
                        <h3 style={{ color: "#2ec2ca", margin: "0 0 0.5em 0" }}>{dish.name} - <span>{dish.price} €</span></h3>
                        <p style={{ margin: "0" }}><span className="spanInCard">origine: </span>{dish.origine || 'non précisé'}</p>
                        <p style={{ margin: "0" }}>{getIngredients()}</p>
                        <p style={{ margin: "0" }}><span className="spanInCard">Accompagné par: </span>{dish.side_dish || 'non précisé'}</p>
                        {dish.description &&
                            <p style={{ margin: "0" }} className="dishDescInCard">{`"${dish.description}"`}</p>
                        }
                        <p style={{ margin: "0" }} className="allerInDishInCard">{getAllergenes()}</p>
                    </Col>
                    <Col span="2">
                        <Button
                            type="primary" ghost
                            onClick={() => handleDeleteDish(dish.id)}
                            style={{ marginRight: "20px" }}
                        >
                            <DeleteFilled />
                        </Button>
                    </Col>
                    <Col span="6" style={{ textAlign: "right" }}>
                        <Image
                            className="dishImageInCard"
                            src={dish.img !== null
                                ? (`https://${SERVER_URL}${dish.img.formats.small.url}`)
                                : (`https://${SERVER_URL}/img/noimage.jpg`)
                            }
                        />
                    </Col>
                </Row>
            </Space>
        )
        : null
}

export default Dish