import React, { useContext } from "react";
import { SERVER_URL } from "../../constants";
import { Image, Button, Space, Row, Col } from "antd";
import { InfoCircleOutlined, DeleteFilled } from "@ant-design/icons";


const Drink = ({ drink, handleDeleteDrink }) => {

    return drink.active
        ? (
            <Space
                className="dishinDish"
                direction="vertical"
                style={{
                    width: "100%",
                    margin: "1em 0"
                }}
            >
                <Row 
                    className="in-dish-00"
                    style={{
                    padding: "0",
                    margin: "0",
                    width: "100%"

                }}>
                    <Col span="16">
                        <h3 style={{ color: "#2ec2ca", margin: "0 0 0.5em 0" }}>{drink.name}</h3>
                        {drink.specs.map((sp,i) => {
                            if (i<1) {
                                return (
                                    <div style={{ width: "100%" }}>
                                        <p style={{ margin: "0" }}><span style={{ fontWeight: "500" }}>{sp.designation}</span> {sp.size ? `${sp.size} cl` : undefined}</p>
                                        {/* <p style={{ margin: "0" }}><span className="spanInCard">Prix: </span><span  style={{fontWeight:"500"}}>{sp.price ? `${sp.price} €` : 'non précisé'}</span></p> */}
                                        {/* <p style={{ margin: "0" }}><span className="spanInCard">Happy Hour: </span>{sp.priceHH !== null ? `${sp.priceHH} €` : 'non précisé'}</p> */}
                                    </div>
                                )
                            }
                        })}
                        {drink.description &&
                            <p style={{ margin: "0" }} className="dishDescInCard">{`"${drink.description}"`}</p>
                        }
                    </Col>
                    <Col span="2">
                        <Button
                            type="primary" ghost
                            onClick={() => handleDeleteDrink(drink.id)}
                            style={{ marginRight: "20px" }}
                        >
                            <DeleteFilled />
                        </Button>
                    </Col>
                    <Col span="6" style={{ textAlign: "right" }}>
                        <Image
                            className="dishImageInCard"
                            src={`https://${SERVER_URL}/uploads/soft_Drinks_Blue_1e66ccfdba.jpg`}
                        />
                    </Col>
                </Row>
            </Space>
        )
        : null
}

export default Drink