import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { UserProvider } from "./Contexts/UserContext";
import { UsersProvider } from "./Contexts/UsersContext";
import { EstablishmentProvider } from "./Contexts/EstablishmentContext";
import { EstablishmentsProvider } from "./Contexts/EstablishmentsContext";
import { GeneralProvider } from "./Contexts/GeneralContext";


import { BrowserRouter as Router } from "react-router-dom";

import client from "./graphql/client";

import { ApolloProvider } from "@apollo/client";

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <UserProvider>
                <UsersProvider>
                    <EstablishmentProvider>
                        <EstablishmentsProvider>
                            <GeneralProvider>
                                <App />
                            </GeneralProvider>
                        </EstablishmentsProvider>
                    </EstablishmentProvider>
                </UsersProvider>
            </UserProvider>
        </Router>
    </ApolloProvider>,
    document.getElementById("root")
);
