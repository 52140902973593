import React, { useContext } from "react";
import { UserContext } from "./Contexts/UserContext"
import { AUTH_TOKEN } from "./constants";
import "./App.less";

import {
    AuthLogin,
    Home,
} from "./Components";

function App() {

    const authToken = localStorage.getItem(AUTH_TOKEN);

    const { user } = useContext(UserContext);

    return !user && !authToken
        ? <AuthLogin />
        : <Home user={user} />

}

export default App;
