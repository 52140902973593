import React, { createContext, useState } from "react";

const exist = () => {
    const localGeneral = JSON.parse(localStorage.getItem('general'))
    if (localGeneral !== null) {
        return localGeneral
    } else {
        return {}
    }
}

export const GeneralContext = createContext({
    general: {},
    setGeneral: () => { },
});

export const GeneralProvider = ({ children }) => {

    const [general, setGeneral] = useState(exist());

    const handleSetGeneral = (general) => {
        setGeneral(general);
        localStorage.removeItem('general');
        localStorage.setItem('general', JSON.stringify(general));
    }

    const [menuVisible, setMenuvisible] = useState(false)

    const showMenu = () => setMenuvisible(!menuVisible)

    return (
        <GeneralContext.Provider value={{ general, handleSetGeneral, menuVisible, showMenu }}>
            {children}
        </GeneralContext.Provider>
    )
}