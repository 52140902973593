import { gql } from "@apollo/client";

// VISUEL

export const UPDATE_DISH_IMAGE = gql`
  mutation(
    $file: Upload!,
    $refId: ID,
    $ref: String,
    $field: String,
    $source: String,
  ) {
    upload(
      file: $file,
      refId: $refId,
      ref: $ref,
      field: $field,
      source: $source,
    ) {
      id
      created_at
      updated_at
      name
    }
  }
`;

export const DELETE_FILE = gql`
  mutation($id: ID!) {
    deleteFile(input: { where: { id: $id } }) {
      file {
        id
        name
      }
    }
  }
`;

export const GET_DISH = gql`
  query($id: ID!) {
    dish(id: $id) {
      id
      name
      created_at
      updated_at
      img {
        id
        created_at
        updated_at
        name
        alternativeText
        caption
        width
        height
        formats
        size
        url
        previewUrl
      }
    }
  }
`;

export const DELETE_DISH = gql`
    mutation deleteDish($id:ID!){
        deleteDish(input: { 
            where: { id: $id } 
            }) {
            dish {
                id
            }
        }
    }
`;

export const UPDATE_DISH = gql`
    mutation updateDish(
        $id: ID!, 
        $name: String,
        $active: Boolean,
        $folded: String,
        $rank: String,
        $price: Float,
        $allergenes: String,
        $origine: String,
        $side_dish: String,
        $description: String,
        $Ingredients: String,
        $establishment: ID,
        $categories: [ID]
        ) {
    updateDish(
        input: {
        where: { id: $id }
        data: { 
            name: $name,
            active: $active,
            folded: $folded,
            rank: $rank,
            price: $price,
            allergenes: $allergenes,
            origine: $origine,
            side_dish: $side_dish,
            description: $description,
            Ingredients: $Ingredients,
            establishment: $establishment,
            categories: $categories,
            }
        }
    ) {
        dish {
        id
        name
        description
        }
    }
}
`;

export const GET_DISHES = gql`
query dishes($establishment: ID) {
    dishes(where : { establishment: $establishment }) {
        id
        name
        active
        folded
        rank
        img {
            id
            related {
                __typename
            }
        }
        price
        allergenes
        origine
        side_dish
        description
        Ingredients
        establishment {
            id
        }
        categories {
            id
        }
    }
}
`;

export const CREATE_DISH = gql`
    mutation createDish(
        $name: String,
        $active: Boolean,
        $folded: String,
        $rank: String,
        $price: Float,
        $allergenes: String,
        $origine: String,
        $side_dish: String,
        $description: String,
        $Ingredients: String,
        $establishment: ID,
        $categories: [ID]
        ) {
    createDish(
        input: {
        data: { 
            name: $name,
            active: $active,
            folded: $folded,
            rank: $rank,
            price: $price,
            allergenes: $allergenes,
            origine: $origine,
            side_dish: $side_dish,
            description: $description,
            Ingredients: $Ingredients,
            establishment: $establishment,
            categories: $categories,
            }
        }
    ) {
        dish {
        id
        name
        active
        folded
        rank
        price
        allergenes
        origine
        side_dish
        description
        Ingredients
        establishment {id}
        categories {id}
        }
    }
}
`;
