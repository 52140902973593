import { gql } from "@apollo/client";

export const DELETE_USER = gql`
    mutation deleteUser($id: ID!) {
    deleteUser(input: { where: { id: $id } }) {
    user {
        id
    }
  }
}
`;

export const GET_USERS = gql`
  query {
    users(sort: "id:desc") {
      id
      created_at
      updated_at
      username
      email
      provider
      confirmed
      blocked
      lastname
      firstname
      role {
        id
      }
      establishments {
        id
      }
    }
  }
`;

export const GET_USER = gql`
  query($id: ID!) {
    user(id: $id) {
      id
      created_at
      updated_at
      username
      email
      provider
      confirmed
      blocked
      role {
        id
      }
      lastname
      firstname
      establishments {
        id
        created_at
        updated_at
        Name
        Description
        Activate
        logo {
            id
        }
        address
        zipcode
        city
        Phone
        facebook
        twitter
        instagram
      }
    }
  }
`;

export const ME = gql`
  query {
    me {
      id
      username
      email
      confirmed
      blocked
      role {
        id
        lastname
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      user {
        id
        username
        email
        confirmed
        blocked
        role {
          id
        }
      }
      jwt
    }
  }
`;


export const CREATE_USER = gql`
    mutation createUser(
        $provider:String, 
        $password:String, 
        $resetPasswordToken:String, 
        $confirmationToken:String, 
        $confirmed:Boolean, 
        $blocked:Boolean, 
        $role:ID!, 
        $email: String!, 
        $username: String!, 
        $lastname: String, 
        $firstname: String
        )
    {
        createUser(
            input: {
                data: {
                    provider: $provider,
                    password: $password,
                    resetPasswordToken: $resetPasswordToken,
                    confirmationToken: $confirmationToken,
                    confirmed: $confirmed,
                    blocked: $blocked,
                    role: $role,
                    email: $email, 
                    username: $username, 
                    lastname: $lastname, 
                    firstname: $firstname
                }
            }
        ) 
        {
            user {
                id
                email
                username
                lastname
                firstname
                created_at
                updated_at
                provider
                confirmed
                blocked
                role {
                    id
                }
            }
        }
    }
`;



export const UPDATE_USER = gql`
mutation updateUser(
    $id: ID!,
    $email: String!,
    $username: String!,
    $provider: String,
    $password: String,
    $resetPasswordToken: String,
    $confirmationToken: String,
    $confirmed: Boolean,
    $blocked: Boolean,
    $role: ID,
    $lastname: String!,
    $firstname: String!,
    $establishments: [ID],
  ) {
    updateUser(
        input: {
            where: { id: $id }
            data: {
                email: $email,
                username: $username,
                provider: $provider,
                password: $password,
                resetPasswordToken: $resetPasswordToken,
                confirmationToken: $confirmationToken,
                confirmed: $confirmed,
                blocked: $blocked,
                role: $role,
                lastname: $lastname,
                firstname: $firstname,
                establishments: $establishments,
            }
        }
    ) {
        user {
            id
            created_at
            updated_at
            email
            username
            provider
            confirmed
            blocked
            role {
                id
            }
            lastname
            firstname
            establishments {
                id
                Name
            }
        }
    }
}
`;
