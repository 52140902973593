import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { AUTH_TOKEN, SERVER_URL } from "../constants";



const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const cache = new InMemoryCache();

const client = new ApolloClient({
    cache,
    link: authLink.concat(
        createUploadLink({
            uri: `https://${SERVER_URL}/graphql`,
            // uri: "http://0.0.0.0:1338/graphql",
        })
    ),
});

export default client;
