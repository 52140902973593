import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { Switch, Button, Card, Input, Space, Row, Col, notification } from "antd";
import { CheckOutlined, CloseOutlined, EyeFilled, CopyFilled, DeleteFilled } from "@ant-design/icons";
import { GET_MENUS, UPDATE_MENU, CREATE_MENU, DELETE_MENU } from "./gql_Menus";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { formatString } from "../../constants";


const OneMenu = ({ menu, listMenus }) => {

    const history = useHistory();

    const { establishment } = useContext(EstablishmentContext)

    const waitin = 1300;

    const [menus] = useLazyQuery(GET_MENUS, {
        onError: (error_m) => console.log("error_m", JSON.stringify(error_m)),
        onCompleted: (data_m) => listMenus(data_m),
        fetchPolicy: "no-cache",
    });

    const [createMenu] = useMutation(CREATE_MENU, {
        onError: (error_cm) => console.log("error_cm :: ", JSON.stringify(error_cm)),
        onCompleted: () => menus({ variables: { establishment: establishment.id } }),
        fetchPolicy: "no-cache",
    });

    const [updateMenu] = useMutation(UPDATE_MENU, {
        onError: (error_um) => console.log("error_um :: ", JSON.stringify(error_um)),
        onCompleted: () => {
            notify('success');
            return menus({ variables: { establishment: establishment.id } })
        },
        fetchPolicy: "no-cache",
    });

    const [deleteMenu] = useMutation(DELETE_MENU, {
        onError: (error_dm) => console.log("error_dm :: ", JSON.stringify(error_dm)),
        onCompleted: () => (
            menus({ variables: { establishment: establishment.id } })
        ),
        fetchPolicy: "no-cache",
    });

    const notify = (type) => {
        notification[type]({
            message: "Menu mis à jour",
        });
    };

    const debouncedUpdate = useCallback(
        debounce((z) => {
            updateMenu({ variables: z })
        }, waitin),
        [],
    );

    const [title, setTitle] = useState()

    const handleChangeTitle = (e) => {
        console.log(":: handleChangeTitle ::", e.target.value)
        const zavars = {
            id: menu.id,
            name: formatString(e.target.value),
        }
        setTitle(formatString(e.target.value))
        debouncedUpdate(zavars)
    };

    const menuTitle = () => {
        return (
            <Input
                bordered={false}
                className="cardTitle"
                placeholder="titre de votre formule"
                value={title ? title : menu.name}
                onChange={(e) => handleChangeTitle(e)}
                style={{ width: "100%", padding: "0", margin: "0", width: "auto" }}
            />
        );
    };

    const [priceInEditMode, setPriceineditmode] = useState(false)
    const [whatThePrice, setWhatThePrice] = useState(menu.price)

    useEffect(() => {

    }, [whatThePrice]);

    const handleChangePrice = (e) => {
        setWhatThePrice(e.target.value)
        const zavars = {
            id: menu.id,
            price: e.target.value,
        }
        debouncedUpdate(zavars)
    }

    const menuPrice = () => {
        return (
            <Input
                // stringMode
                className="cardTitle input-prix"
                bordered={false}
                placeholder="prix"
                value={priceInEditMode ? whatThePrice : `${menu.price} €`}
                onFocus={() => setPriceineditmode(true)}
                onBlur={() => setPriceineditmode(false)}
                onChange={(e) => handleChangePrice(e)}
            />
        )
    }

    const handleChangeDescription = (e) => {

        const zavars = {
            id: menu.id,
            description: e.target.value,
        }
        debouncedUpdate(zavars)
    }

    const menuDescription = () => {
        return (
            <Space>
                <Input
                    bordered={false}
                    placeholder="description de votre formule"
                    defaultValue={menu.description}
                    onChange={(e) => handleChangeDescription(e)}
                    style={{
                        fontStyle: "italic",
                        padding: "0",
                        margin: "10px 0 0 0",
                        width: "250px"
                    }}
                />
            </Space>
        )
    }

    const handleActivate = () => {
        const zavars = {
            id: menu.id,
            active: !menu.active,
        };
        debouncedUpdate(zavars)
    };

    const active = () => {
        return (
            <Switch
                size="small"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(e) => handleActivate(e)}
                defaultChecked={menu.active}
            />
        );
    };

    const handleDuplicateMenu = () => {
        // console.log(":: handleDuplicateMenu ::")
        const newMenu = { ...menu }
        console.log("newMenu :: ", newMenu)
        newMenu.dishes = menu.dishes.map(dish => dish.id)
        newMenu.drinks = menu.drinks.map(drink => drink.id)
        newMenu.wines = menu.wines.map(wine => wine.id)
        newMenu.establishment = establishment.id
        delete newMenu.id;
        delete newMenu.__typename;
        createMenu({ variables: newMenu })
    }

    const handleDeleteMenu = (e) => {
        console.log(":: handleDeleteMenu ::", e)
        deleteMenu({ variables: { id: e } })
    }

    const seeMenuDetails = () => {
        history.push({
            pathname: "/formuledetails",
            menu: menu,
            establishment: establishment,
        })
    }

    //////////////////////////////////// return ////////
    //////////////////////////////////// return ////////

    return (
        <Card style={{
            borderColor: "#2ec2ca",
            padding: "10px 0",
            marginBottom: "16px"
        }}>
            <Row 
                className="in-menus-00"
                style={{
                padding: "0",
                margin: "0",
                width: "100%"
            }}>
                <Col
                    className="in-menus-01"
                    style={{
                        padding: "0",
                        margin: "0",
                        width: "50%"
                    }}>
                    <Space>
                        <Button
                            onClick={() => seeMenuDetails()}
                            type='primary' ghost
                        >
                            {menu.dishes.length > 0 || menu.drinks.length > 0 || menu.wines.length > 0
                                ? <span style={{ fontWeight: "700" }}>{menu.dishes.length + menu.drinks.length + menu.wines.length}</span>
                                : <EyeFilled />
                            }
                        </Button>
                        {active()}
                        {menuTitle()}
                    </Space>
                </Col>
                <Col style={{
                    padding: "0",
                    margin: "0",
                    width: "50%",
                    textAlign: "right",
                }}>
                    <Space className="in-menus-02">
                        {menuPrice()}
                        <Button
                            className="bout-duplicate"
                            type="primary" ghost
                            style={{ marginRight: "16px" }}
                            onClick={() => handleDuplicateMenu(menu.id)}
                        >
                            <CopyFilled />
                        </Button>
                        <Button
                            type="primary" ghost
                            onClick={() => handleDeleteMenu(menu.id)}
                        >
                            <DeleteFilled />
                        </Button>
                    </Space>
                </Col>
            </Row>

            {menuDescription()}
        </Card>
    );
};

export default OneMenu;
