export const AUTH_TOKEN = "auth-token";


export const SERVER_URL = "api.prabel.pro"
// export const SERVER_URL = "0.0.0.0:1338"

export const formatString = (str) => {
    return str
        .replace(/(\B)[^ ]*/g, match => (match.toLowerCase()))
        .replace(/^[^ ]/g, match => (match.toUpperCase()));
}

