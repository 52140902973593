import React, { useState } from "react";
import { Upload, Button, Card } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
    DELETE_FILE,
    UPDATE_DISH_IMAGE,
    GET_DISH,
} from "./gql_Dishes";

import { GET_CATEGORIES } from "./gql_Categories";

const GestionLogo = ({ dish, categories, cats }) => {

    // console.log("categories :: ", categories)
    // console.log("cats :: ", cats[0].establishment.id)


    const [updateDishImage, { loading: loading_udi, error: error_udi, data: data_udi }] = useMutation(
        UPDATE_DISH_IMAGE,
        {
            onCompleted: (data_udi) => categories({ variables: { establishment: cats[0].establishment.id } }),
        }
    );

    const [deleteFile, { error: error_df, data: data_df }] = useMutation(
        DELETE_FILE,
        {
            onCompleted: (data_udi) => categories({ variables: { establishment: cats[0].establishment.id } }), 
        }
    );

    let vars = {};

    const fireFry = () => {
        setTimeout(function () {
            //   console.log(vars);
            updateDishImage({
                variables: vars,
            });
        }, 1000);
    };

    const deleteImage = () => {
        deleteFile({ variables: { id: dish.img.id } });
    };

    let [fileList, setFileList] = useState([]);

    let i = 0;

    const handleUpload = ({ fileList }) => {
        if (i < 1) {
            setFileList([fileList[0].originFileObj]);

            vars = {
                id: dish.id,
                file: fileList[0].originFileObj,
                refId: dish.id,
                ref: "Dish",
                field: "img",
            };

            fireFry();
            i++;
        }
    };

    if (error_udi) return <p>{JSON.stringify(error_udi)}</p>;
    if (error_df) return <p>{JSON.stringify(error_df)}</p>;
    if (loading_udi) return <p>Loading...</p>;

    return (
        <Card bordered={false} style={{margin:"1em 0"}}>
            {dish.img ? (
                <Button danger onClick={() => deleteImage()}>Supprimer le visuel</Button>
            ) : (
                    <Upload
                        listType="picture"
                        fileList={fileList.name}
                        onChange={(fileList) => handleUpload(fileList)}
                    >
                        <Button icon={<UploadOutlined />}>Ajouter un visuel</Button>
                    </Upload>
                )}
        </Card>
    );
};

export default GestionLogo;
