import React, { useState, useContext } from "react";
import Flag from "react-world-flags";
import { Select } from "antd";
import { notification } from "antd";
import { GeneralContext } from "../../Contexts/GeneralContext";

const { Option } = Select;

const FlagSelect = () => {

    const { general, handleSetGeneral } = useContext(GeneralContext);

    const handleChangeLanguage = (lang) => {
        const lesLangues = {
            fr: "Français",
            en: "English",
            it: "Italian",
            ru: "Russian",
            "zh-Hans": "Chinese",
            es: "Spanish",
            et: "Estonian",
            de: "German",
            ko: "Korean",
            "pt-pt": "Portuguese",
        }
        notification.success({ message: `Langue : ${lesLangues[lang]} ` })
        handleSetGeneral({ ...general, langue: lang });
    }

    function handleChange(value) {
        handleChangeLanguage(value);
        // console.log(`selected ${value}`);
    }

    return (
        <div>
            <Select
                value={general.langue ? general.langue : "fr"}
                style={{ width: "70%", marginLeft:"24px", marginBottom:"20px" }}
                onChange={handleChange}
            >
                <Option value="fr"><Flag className="flag" code={ "fr" } /> Français</Option>
                <Option value="en"><Flag className="flag" code={ "sh" } /> English</Option>
                <Option value="it"><Flag className="flag" code={ "it" } /> Italian</Option>
                <Option value="ru"><Flag className="flag" code={ "ru" } /> Russian</Option>
                <Option value="zh-Hans"><Flag className="flag" code={ "cn" } /> Chinese</Option>
                <Option value="es"><Flag className="flag" code={ "es" } /> Spanish</Option>
                <Option value="et"><Flag className="flag" code={ "ee" } /> Estonian</Option>
                <Option value="de"><Flag className="flag" code={ "de" } /> German</Option>
                <Option value="ko"><Flag className="flag" code={"kr"} /> Korean</Option>
                <Option value="pt-pt"><Flag className="flag" code={"pt"} /> Portuguese</Option>
            </Select>
        </div>
    );
};

export default FlagSelect;
