import React, { createContext, useEffect, useState } from "react";

const exist = () => {
    const localUsers = JSON.parse(localStorage.getItem('users'))
    if (localUsers !== null) {
        return localUsers
    } else {
        return undefined
    }
}

export const UsersContext = createContext({
    users: {},
    setUsers: () => { },
});

export const UsersProvider = ({ children }) => {

    const [users, setUsers] = useState(exist());

    const handleSetUsers = (users) => {
        setUsers(users);
        localStorage.removeItem('users');
        localStorage.setItem('users', JSON.stringify(users));
    }

    return (
        <UsersContext.Provider value={{ users, handleSetUsers, setUsers }}>
            {children}
        </UsersContext.Provider>
    )
}