import { gql } from "@apollo/client";

export const GET_WINE_TYPES = gql`
    query wineTypes($establishments: ID) {
        wineTypes(where: { establishments: $establishments }) {
            id
            name
            wines {
            id
            active
            name
            country
            year
            region
            designation
            producer
            description
            organic
            specs {
                id
                size
                price
                designation
                priceHH
            }
            cards {
                id
            }
            menus {
                id
            }
            }
            establishments {
            id
            }
        }
    }
`;

export const UPDATE_WINE = gql`
    mutation updateWine($id: ID!
        $wine_types: [ID]
        $specs: [editComponentContainersContainerInput]
        $description: String
        $name: String
        $active: Boolean
        $organic: Boolean
        $country: String
        $year: Int
        $region: String
        $designation: String
        $producer: String
    ) {
        updateWine(
            input: { 
                where: { id: $id }, 
                data: { 
                    wine_types: $wine_types
                    specs: $specs
                    description: $description
                    name: $name 
                    active: $active
                    organic: $organic
                    country: $country
                    year: $year
                    region: $region
                    designation: $designation
                    producer: $producer
                } }
        ) {
            wine {
                id
                name
                wine_types {
                    id
                    name
                }
            }
        }
    }
`;

export const CREATE_WINE = gql`
    mutation createWine(
        $name: String
        $wine_types: [ID]
        $specs: [ComponentContainersContainerInput]
        $description: String
        $active: Boolean
        $organic: Boolean
        $country: String
        $year: Int
        $region: String
        $designation: String
        $producer: String
    ) {
        createWine(
            input: {
                data: {
                    name: $name
                    wine_types: $wine_types
                    specs: $specs
                    description: $description
                    active: $active
                    organic: $organic
                    country: $country
                    year: $year
                    region: $region
                    designation: $designation
                    producer: $producer
                }
            }
        ) {
            wine {
                id
                name
                wine_types {
                    id
                    name
                }
                specs {
                    id
                    size
                    price
                    priceHH
                    designation
                }
            }
        }
    }
`;

export const DELETE_WINE = gql`
    mutation deleteWine($id: ID!) {
        deleteWine(input: { where: { id: $id } }) {
            wine {
                id
                name
            }
        }
    }
`;
