import React, { useContext, useState, useEffect, useCallback } from "react";
import { Card, Input, notification, Button, Image, Space, Select, Switch, Collapse, Row, Col } from "antd";
import { CloseOutlined, PlusSquareOutlined, CheckOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useMutation } from "@apollo/client";
import { UPDATE_WINE, DELETE_WINE, CREATE_WINE } from "./gql_Wines";
import { CopyFilled, DeleteFilled, PlusCircleFilled } from "@ant-design/icons";

//////// NEW
import WineSpecs from "./WineSpecs";

import { GeneralContext } from "../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";

const { TextArea } = Input;
const { Option } = Select;

const Wine = ({ wine, types, type, typeID, getWineTypes }) => {

    const { general, handleSetGeneral } = useContext(GeneralContext);
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext)

    const [createWine, { error: error_cw, data: data_cw }] = useMutation(CREATE_WINE, {
        onError: (error_cw) => console.log("error_cw :: ", JSON.stringify(error_cw)),
        onCompleted: (data_cw) => {
            notify('success');
            return getWineTypes({ variables: { establishments: types[0].establishments[0].id } })
        },
        fetchPolicy: "no-cache",
    });

    const [updateWine, { error: error_uw, data: data_uw }] = useMutation(UPDATE_WINE, {
        onError: (error_uw) => console.log("error_uw :: ", JSON.stringify(error_uw)),
        onCompleted: (data_uw) => {
            notify('success');
            return getWineTypes({ variables: { establishments: types[0].establishments[0].id } })
        },
        fetchPolicy: "no-cache",
    });

    const [deleteWine, { error: error_dw, data: data_dw }] = useMutation(DELETE_WINE, {
        onError: (error_dw) => console.log("error_dw :: ", JSON.stringify(error_dw)),
        onCompleted: (data_dw) => {
            notify('success');
            return getWineTypes({ variables: { establishments: types[0].establishments[0].id } })
        },
        fetchPolicy: "no-cache",
    });

    const waitin = 1500;

    const notify = (type) => {
        notification[type]({
            message: "Mise à jour réussie",
        });
    };

    const changeType = (e) => {
        const wineToUp = { id: wine.id, wine_types: e };
        delete wineToUp.__typename;
        updateWine({ variables: wineToUp });
    };

    const actualType = () => {
        return type;
    };

    const handleUpdateWine = (e) => {
        // console.log("e :: ", e.target.name)
        // console.log("e :: ", e.target.value)
        const wineToUp = { ...wine, [e.target.name]: e.target.value };
        delete wineToUp.__typename;
        wineToUp.specs.map(s => delete s.__typename)
        wineToUp.year = JSON.parse(wineToUp.year)
        updateWine({ variables: wineToUp });
    };

    const handleUpdateWineDescription = (e) => {
        // console.log("e :: ", e.target.value)
        const wineToUp = { ...wine, description: e.target.value };
        delete wineToUp.__typename;
        wineToUp.specs.map(s => delete s.__typename)
        wineToUp.year = JSON.parse(wineToUp.year)
        updateWine({ variables: wineToUp });
    }

    const handleDeleteWine = (e) => {
        deleteWine({ variables: { id: e } });
        setTempWineTitle(undefined)
    };

    const handleDuplicateWine = (e, at) => {
        const whatType = types.filter((t) => t.name === at);
        const wineToCreate = { ...wine, wine_types: whatType[0].id };
        delete wineToCreate.__typename;
        delete wineToCreate.id;
        wineToCreate.specs.map((s) => {
            delete s.id;
            delete s.__typename;
        })
        createWine({ variables: wineToCreate });
    };

    const handleCreateWine = () => {
        const newWine = {
            name: 'nom',
            description: 'décrivez ce vin...',
            wine_types: typeID,
            active: true,
            country: '',
            year: undefined,
            region: '',
            designation: '',
            producer: '',
            specs: [{
                designation: "Bouteille",
                size: 75,
                price: 30,
                priceHH: 15
            }]
        }
        createWine({ variables: newWine })
    }

    const contenants = ["Verre", "Bouteille", "Pichet", "Magnum"];

    const [tempWineTitle, setTempWineTitle] = useState()

    const debouncedUpdate = useCallback(
        debounce((z) => {
            updateWine({ variables: z })
        }, waitin),
        [],
    );

    const handleUpdateWineName = (e) => {
        setTempWineTitle(e.target.value)
        const zavars = {
            id: wine.id,
            name: e.target.value,
            specs: [...wine.specs]
        }
        zavars.specs.map((s) => {
            delete s.__typename
        })
        setTempWineTitle(e.target.value)
        debouncedUpdate(zavars)
    }

    useEffect(() => {
        setTempWineTitle(wine.name)
    }, []);

    const wineTitle = () => {
        return (
            <Input
                className="wineTitle"
                onChange={(e) => handleUpdateWineName(e)}
                placeholder="Nom du vin"
                bordered={false}
                name="name"
                value={tempWineTitle ? tempWineTitle : wine.name}
                style={{ margin: "16px 0 10px 10px" }}
            />
        );
    };

    const updateContenantSpec = (e, id) => {
        const newSpecs = wine.specs.map((s) => {
            delete s.__typename
            return s.id === id ? (s.designation = e, s) : s
        })
        const newWine = {
            ...wine,
            specs: newSpecs
        }
        updateWine({
            variables: newWine
        })
    };


    const updateSpecs = (e, id) => {
        console.log("id :: ", e.target.name, e.target.value, id)
        const newSpecs = wine.specs.map((s) => {
            delete s.__typename
            return s.id === id ? (s[e.target.name] = parseFloat(e.target.value.replace(/,/g, '.').replace(" cl", "").replace(" €", "")), s) : s
        })
        const newWine = {
            ...wine,
            specs: newSpecs
        }
        debouncedUpdate(newWine)
    };

    const handleAddSpecs = () => {
        wine.specs.map((s) => {
            delete s.__typename
        })
        const newWine = {
            ...wine,
            specs: [...wine.specs, {
                size: 75,
                price: 30,
                priceHH: 15,
                designation: 'Bouteille',
            }]
        }
        updateWine({
            variables: newWine
        })
    }

    const handleDeleteSpec = (e) => {
        wine.specs.map((s) => {
            delete s.__typename
        })
        const newSpecs = wine.specs.filter(s => s.id !== e)
        const newWine = { ...wine, specs: newSpecs }
        updateWine({
            variables: newWine
        })
    }

    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////

    const getWineSpecs = () => { 

        return wine.specs.map((s,i) => {
            return <WineSpecs
                key={i}
                specs={s}
                updateContenantSpec={updateContenantSpec}
                updateSpecs={updateSpecs}
                handleDeleteSpec={handleDeleteSpec}
                contenants={contenants}
            />
            }
        )

        
    }

    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////
    ////////////////////////////////////////////////////

    const wineDescription = () => {
        return (
            <TextArea
                placeholder="décrivez votre vin ou champagne..."
                onChange={debounce((e) => handleUpdateWineDescription(e), waitin)}
                name="description"
                bordered={false}
                className="restoDetailsDescription"
                defaultValue={wine.description}
                style={{ width: "100%" }}
            />
        );
    };

    const getElName = (el) => {
        return el.name;
    };

    const country = () => {
        return (
            <Input
                defaultValue={wine.country}
                placeholder="Pays"
                onChange={debounce((e) => handleUpdateWine(e), waitin)}
                name="country"
                bordered={false}
                addonBefore="Pays"
            />
        )
    }

    const year = () => {
        return (
            <Input
                defaultValue={wine.year}
                placeholder="Milésime"
                onChange={debounce((e) => handleUpdateWine(e), waitin)}
                name="year"
                bordered={false}
                addonBefore="Milésime"
            />
        )
    }

    const region = () => {
        return (
            <Input
                defaultValue={wine.region}
                placeholder="Région"
                onChange={debounce((e) => handleUpdateWine(e), waitin)}
                name="region"
                bordered={false}
                addonBefore="Région"
            />
        )
    }

    const designation = () => {
        return (
            <Input
                defaultValue={wine.designation}
                placeholder="Appellation"
                onChange={debounce((e) => handleUpdateWine(e), waitin)}
                name="designation"
                bordered={false}
                addonBefore="Appellation"
            />
        )
    }

    const producer = () => {
        return (
            <Input
                defaultValue={wine.producer}
                placeholder="Producteur"
                onChange={debounce((e) => handleUpdateWine(e), waitin)}
                name="producer"
                bordered={false}
                addonBefore="Producteur"
            />
        )
    }

    const handleOrganic = () => {
        wine.specs.map((s) => {
            delete s.__typename
        })
        const zavars = {
            id: wine.id,
            organic: !wine.organic,
            specs: [...wine.specs]
        }
        updateWine({ variables: zavars })
    }

    const organic = () => {
        return (
            <Space>
                <span style={{ color: "#2ec2ca" }}>{'Vin '}</span>
                <Switch
                    size='small'
                    checkedChildren="Bio"
                    unCheckedChildren="Non Bio"
                    name="organic"
                    onChange={() => handleOrganic()}
                    defaultChecked={wine.organic}
                />
            </Space>
        )
    }

    const handleActivate = () => {
        console.log("handleActivate :: ")
        wine.specs.map((s) => {
            delete s.__typename
        })
        const zavars = {
            id: wine.id,
            active: !wine.active,
            specs: [...wine.specs]
        }
        updateWine({ variables: zavars })
    }

    const active = () => {
        return (
            <Switch
                size='small'
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(e) => handleActivate(e)}
                defaultChecked={wine.active}
                className="switch"
            />
        )
    }

    /////////////////////////////////////////////////////////////// RETURN ////
    /////////////////////////////////////////////////////////////// RETURN ////

    return (
        <Card
            bordered={true}
            style={{ borderColor: "#2ec2ca", marginBottom: "1em" }}
            actions={[
                <Select
                    style={{ width: "80%" }}
                    onChange={(el) => changeType(el)}
                    defaultValue={actualType()}>
                    {types.map((el) => {
                        return <Option key={el.id}>{getElName(el)}</Option>;
                    })}
                </Select>,
                <CopyFilled onClick={() => handleDuplicateWine(wine.id, actualType())} />,
                <PlusCircleFilled onClick={() => handleCreateWine()} />,
                <DeleteFilled onClick={() => handleDeleteWine(wine.id)} />,
            ]}>
            <Space className="in-wine-01">
                {active()}{wineTitle()}
                <Button type="link" onClick={() => handleAddSpecs()}>
                    <PlusSquareOutlined />
                </Button>
            </Space>
            <Space style={{ width: "100%", margin: "0 0 1em 0" }} className="in-wine-02">
                <Space direction="vertical">{getWineSpecs()}</Space>
                {wineDescription()}
            </Space>

            <Space style={{ marginBottom: "4px" }} className="in-wine-03">
                {designation()}
                {producer()}
                {organic()}
            </Space>
            <Space style={{ marginBottom: "8px" }} className="in-wine-04">
                {country()}
                {year()}
                {region()}
            </Space>

        </Card>
    );
};

export default Wine;
