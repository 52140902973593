import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { PageHeader, Switch, Button, Card, Tabs, Input, Empty, Space, Row, Col, notification } from "antd";
import { CheckOutlined, CloseOutlined, EyeFilled, CopyFilled, DeleteFilled } from "@ant-design/icons";
import { GET_CARDS, UPDATE_CARD, CREATE_CARD, DELETE_CARD } from "./gql_Cards";
import { Link, useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { SERVER_URL, formatString } from "../../constants";


const OneCard = ({ card, listCards }) => {

    const history = useHistory();

    const { establishment } = useContext(EstablishmentContext)

    const waitin = 1500;

    const [cards, { error: error_gc, data: data_gc }] = useLazyQuery(GET_CARDS, {
        onError: (error_gc) => console.log("error_gc", JSON.stringify(error_gc)),
        onCompleted: (data_gc) => listCards(data_gc),
        fetchPolicy: "no-cache",
    });

    const [createCard, { error: error_cc, data: data_cc }] = useMutation(CREATE_CARD, {
        onError: (error_cc) => console.log("error_cc :: ", JSON.stringify(error_cc)),
        onCompleted: (data_cc) => cards({ variables: { establishments: establishment.id } }),
        fetchPolicy: "no-cache",
    });

    const [updateCard, { error: error_uc, data: data_uc }] = useMutation(UPDATE_CARD, {
        onError: (error_uc) => console.log("error_uc :: ", JSON.stringify(error_uc)),
        onCompleted: (data_uc) => {
            notify('success');
            return cards({ variables: { establishments: establishment.id } })
        },
        fetchPolicy: "no-cache",
    });

    const [deleteCard, { error: error_dc, data: data_dc }] = useMutation(DELETE_CARD, {
        onError: (error_dc) => console.log("error_dc :: ", JSON.stringify(error_dc)),
        onCompleted: (data_dc) => (
            cards({ variables: { establishments: establishment.id } }),
            console.log("data_dc :: ", data_dc)
        ),
        fetchPolicy: "no-cache",
    });

    const notify = (type) => {
        notification[type]({
            message: "Carte mise à jour",
        });
    };

    const debouncedUpdate = useCallback(
        debounce((z) => {
            updateCard({ variables: z })
        }, waitin),
        [],
    );

    const [title, setTitle] = useState()

    const handleChangeTitle = (e) => {
        console.log(":: handleChangeTitle ::", e.target.value)
        const zavars = {
            id: card.id,
            name: formatString(e.target.value),
        }
        setTitle(formatString(e.target.value))
        debouncedUpdate(zavars)
    };

    const cardTitle = () => {
        return (
            <Input
                bordered={false}
                className="cardTitle"
                placeholder="titre de la carte"
                value={title ? title : card.name}
                onChange={(e) => handleChangeTitle(e)}
                style={{ width: "100%", padding: "0", margin: "0", width: "auto" }}
            />
        );
    };

    const handleChangeDescription = (e) => {

        const zavars = {
            id: card.id,
            description: e.target.value,
        }
        debouncedUpdate(zavars)
    }

    const cardDescription = () => {
        return (
            <Space>
                <Input
                    bordered={false}
                    placeholder="description de votre carte"
                    defaultValue={card.description}
                    onChange={(e) => handleChangeDescription(e)}
                    style={{ fontStyle: "italic", padding: "0", margin: "10px 0 0 0", width: "40vw" }}
                />
            </Space>
        )
    }

    const handleActivate = () => {
        const zavars = {
            id: card.id,
            active: !card.active,
        };
        updateCard({ variables: zavars });
    };

    const active = () => {
        return (
            <Switch
                size="small"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(e) => handleActivate(e)}
                defaultChecked={card.active}
            />
        );
    };

    const handleDuplicateCard = () => {
        // console.log(":: handleDuplicateCard ::")
        const newCard = { ...card };
        newCard.dishes = card.dishes.map(dish => dish.id)
        newCard.drinks = card.drinks.map(drink => drink.id)
        newCard.wines = card.wines.map(wine => wine.id)
        newCard.establishments = [establishment.id]
        delete newCard.id;
        delete newCard.__typename;
        createCard({ variables: newCard })
    }

    const handleDeleteCard = (e) => {
        // console.log(":: handleDeleteCard ::", e)
        deleteCard({ variables: { id: e } })
    }

    const seeCardDetails = () => {
        history.push({
            pathname: "/cartedetails",
            card: card,
            establishment: establishment,
        })
    }

    //////////////////////////////////// return ////////
    //////////////////////////////////// return ////////

    return (
        <Card style={{
            borderColor: "#2ec2ca",
            padding: "10px 0",
            marginBottom: "16px"
        }}>
            <Row 
                className="in-card-00"
                style={{
                padding: "0",
                margin: "0",
                width: "100%"
            }}>
                <Col 
                    className="in-card-01"
                    style={{
                    padding: "0",
                    margin: "0",
                    width: "50%"
                }}>
                    <Space>
                        <Button
                            onClick={() => seeCardDetails()}
                            type='primary' ghost
                        >
                            {card.dishes.length > 0 || card.drinks.length > 0 || card.wines.length > 0
                                ? <span style={{ fontWeight: "700" }}>{card.dishes.length + card.drinks.length + card.wines.length}</span>
                                : <EyeFilled />
                            }
                        </Button>
                        {active()}
                        {cardTitle()}
                    </Space>
                </Col>
                <Col 
                    className="in-card-02"
                    style={{
                    padding: "0",
                    margin: "0",
                    width: "50%",
                    textAlign: "right"
                }}>
                    <Button
                        type="primary" ghost
                        style={{ marginRight: "16px" }}
                        onClick={() => handleDuplicateCard(card.id)}
                    >
                        <CopyFilled />
                    </Button>
                    <Button
                        type="primary" ghost
                        onClick={() => handleDeleteCard(card.id)}
                    >
                        <DeleteFilled />
                    </Button>
                </Col>
            </Row>
            {cardDescription()}
        </Card>
    );
};

export default OneCard;
