import React, { useContext } from "react";
import { UserContext } from "../../Contexts/UserContext";
import { GeneralContext } from "../../Contexts/GeneralContext";

import { Layout, Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import PrivateRoute from "../../Components/Auth/PivateRoute";
import { SettingOutlined, MenuOutlined } from "@ant-design/icons";

import {
    Settings,
    UsersSettings,
    Cards,
    Dishes,
    Drinks,
    Menus,
    Wines,
    Establishments,
    Establishment,
} from "../../Components";

import OneCardDetails from "../Cards/OneCardDetails";
import OneMenuDetails from "../Menus/OneMenuDetails";

import FlagSelect from "./FlagSelect";

const { Header, Content, Footer, Sider } = Layout;

const Home = () => {

    const history = useHistory();

    const { user } = useContext(UserContext);

    const { general, menuVisible, showMenu } = useContext(GeneralContext);

    general.page
        ? (history.push(general.page))
        : (history.push("/restaurants"))

    return (
        <Layout>
            <Header className="header" style={{ position: 'fixed', zIndex: 1, width: '100%', height: "90px", padding: "0 8vw" }}>
                <MenuOutlined className="burger" onClick={() => showMenu()} />
                <Link
                    className="logo"
                    to="/restaurants"
                />
                <h1 className="topTitle">
                    {user.role.id === "3"
                        ? "Administration"
                        : user.establishments[0].Name}
                </h1>
                <Menu theme="light" mode="horizontal" defaultSelectedKeys={["2"]}>
                    <Menu.Item key="2">
                        <Link to="/settings">
                            <SettingOutlined style={{ margin: "0", height: "100%" }} />
                        </Link>
                    </Menu.Item>
                </Menu>
            </Header>
            <Content style={{ margin: "4vh 0 0 0", padding: "110px 8vw 0 8vw" }}>

                <Layout
                    className="site-layout-background"
                    style={{ padding: "25px 0" }}
                >
                    <Sider className={`site-layout-background ${menuVisible ? 'active' : null}`}>

                        <Menu
                            mode="inline"
                            defaultSelectedKeys={["1"]}
                            defaultOpenKeys={["sub1"]}
                        >
                            <FlagSelect />

                            <Menu.Item key="1">
                                <Link to="/restaurants">
                                    {user.role.id === "3"
                                        ? "Retaurants"
                                        : user.establishments[0].Name}
                                </Link>
                            </Menu.Item >
                            <Menu.Item key="2">
                                <Link to="/cartes">Cartes</Link>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <Link to="/formules">Formules</Link>
                            </Menu.Item>
                            <Menu.Item key="4">
                                <Link to="/plats">Plats</Link>
                            </Menu.Item>
                            <Menu.Item key="5">
                                <Link to="/boissons">Boissons</Link>
                            </Menu.Item>
                            <Menu.Item key="6">
                                <Link to="/vins">Vins & Champagnes</Link>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Content style={{ padding: "0 24px", minHeight: 380 }}>
                        <PrivateRoute exact path="/userssettings">
                            <UsersSettings />
                        </PrivateRoute>
                        <PrivateRoute exact path="/settings">
                            <Settings />
                        </PrivateRoute>
                        <PrivateRoute exact path="/restaurants">
                            {user.role.id === "3" ? (
                                <Establishments />
                            ) : (
                                    <Establishment
                                        propId={user.establishments[0].id}
                                        role={user.role.id}
                                    />
                                )}
                        </PrivateRoute>
                        <PrivateRoute exact path="/restaurant/:id">
                            <Establishment
                                propId={user.establishments.length > 0
                                    ? user.establishments[0].id
                                    : null
                                }
                                role={user.role.id} />
                        </PrivateRoute>
                        <PrivateRoute exact path="/cartes">
                            <Cards />
                        </PrivateRoute>
                        <PrivateRoute exact path="/cartedetails">
                            <OneCardDetails />
                        </PrivateRoute>
                        <PrivateRoute exact path="/formules">
                            <Menus />
                        </PrivateRoute>
                        <PrivateRoute exact path="/formuledetails">
                            <OneMenuDetails />
                        </PrivateRoute>
                        <PrivateRoute exact path="/plats">
                            <Dishes />
                        </PrivateRoute>
                        <PrivateRoute exact path="/boissons">
                            <Drinks />
                        </PrivateRoute>
                        <PrivateRoute exact path="/vins">
                            <Wines />
                        </PrivateRoute>
                    </Content>
                </Layout>
            </Content>
            <Footer style={{ textAlign: "center" }}>
                Safe Menu ©2021 by Capucine - Monaco
          </Footer>
        </Layout>
    )
}

export default Home;