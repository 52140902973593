import React, { createContext, useEffect, useState } from "react";

const exist = () => {
    const localEstablishments = JSON.parse(localStorage.getItem('establishments'))
    if (localEstablishments !== null) {
        return localEstablishments
    } else {
        return {}
    }
}

// console.log("localEstablishments :: ", exist())

export const EstablishmentsContext = createContext({
    establishments: {},
    setEstablishments: () => { },
});

export const EstablishmentsProvider = ({ children }) => {

    const [establishments, setEstablishments] = useState(exist());

    const handleSetEstablishments = (establishments) => {
        setEstablishments(establishments);
        localStorage.removeItem('establishments');
        localStorage.setItem('establishments', JSON.stringify(establishments));
    }

    return (
        <EstablishmentsContext.Provider value={{ establishments, handleSetEstablishments }}>
            {children}
        </EstablishmentsContext.Provider>
    )
}