import React, { useState, useContext, useEffect } from "react";
import { EstablishmentsContext } from "../../Contexts/EstablishmentsContext";
import { UserContext } from "../../Contexts/UserContext";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { Link } from "react-router-dom";
import {
    Button,
    Tooltip,
    Table,
    Input,
    InputNumber,
    Popconfirm,
    Form,
    Switch,
    PageHeader
} from "antd";
import {
    CloseOutlined,
    CheckOutlined,
    EditFilled,
    SaveFilled,
    CloseCircleFilled,
} from "@ant-design/icons";
import { useQuery, useMutation, gql } from "@apollo/client";
import {
    GET_ESTABLISHMENTS,
    UPDATE_ESTABLISHMENT,
    CREATE_ESTABLISHMENT,
    CREATE_DRINK_TYPES,
    CREATE_WINE_TYPES,
} from "./gql_Establishments";

import { formatString } from "../../constants"



const Establishments = () => {

    const { user } = useContext(UserContext)
    const { establishments, handleSetEstablishments } = useContext(EstablishmentsContext);
    const { general, handleSetGeneral, showMenu, menuVisible } = useContext(GeneralContext);

    useEffect(() => {
        handleSetGeneral({ ...general, page: '/restaurants' });

        if (menuVisible === true) {
            showMenu()
        }

    }, []);

    const { loading, error, data } = useQuery(GET_ESTABLISHMENTS, {
        onCompleted: (data) => handleSetEstablishments(data.establishments)
    });

    const [createDrinkType, { data: data_cdt, error: error_cdt }] = useMutation(CREATE_DRINK_TYPES, {
        onError: (error_cdt) => console.log("error_cdt :: ", JSON.stringify(error_cdt)),
        onCompleted: (data_cdt) => console.log("data_cdt :: ", data_cdt),
    });

    const [createWineType, { data: data_cwt, error: error_cwt }] = useMutation(CREATE_WINE_TYPES, {
        onError: (error_cwt) => console.log("error_cwt :: ", JSON.stringify(error_cwt)),
        onCompleted: (data_cwt) => console.log("data_cwt :: ", data_cwt),
    });

    const [createEstablishment, { data: data_ce }] = useMutation(CREATE_ESTABLISHMENT, {
        onCompleted: (data_ce) => {
            createNeededTypes(data_ce); // pour peupler les boissons et vins
            return handleSetEstablishments({ ...establishments }, { ...data_ce.establishments })
        },
    });

    const [updateEstablishment, { data: data_ue }] = useMutation(UPDATE_ESTABLISHMENT, {
        onCompleted: (data_ue) => handleSetEstablishments({ ...establishments }, { ...data_ue.establishments }),
    });

    const createNeededTypes = (datas) => {

        // console.log("datas :: ", datas)

        const winesTypes = [
            "Vin Rouge",
            "Vin Rosé",
            "Vin Blanc",
            "Autre Vin",
            "Champagne",]

        const drinkTypes = [
            "Soft",
            "Jus",
            "Eau Plate",
            "Eau Gazeuse",
            "Apéritif",
            "Bière",
            "Alcool",
            "Boisson Chaude"
        ]

        drinkTypes.map(d => {
            console.log("d :: ", d)
            return createDrinkType({
                variables: {
                    establishments: datas.createEstablishment.establishment.id,
                    name: d
                }
            })
        })

        winesTypes.map(d => {
            console.log("d :: ", d)
            return createWineType({
                variables: {
                    establishments: datas.createEstablishment.establishment.id,
                    name: d
                }
            })
        })
    }

    const handleCreateEstablishments = () => {
        const newData = {
            Name: "* Nom *",
            Description: "* Desc *",
            Activate: true,
            address: "* Adresse *",
            zipcode: "*****",
            city: "* Ville *",
        };

        createEstablishment({
            variables: newData,
            update: (cache, { data: { createEstablishment } }) => {
                const data = cache.readQuery({ query: GET_ESTABLISHMENTS });

                const newEstablishment = {};
                newEstablishment["__typename"] = "Establishment";
                newEstablishment["id"] = createEstablishment.establishment.id;
                newEstablishment["Name"] = createEstablishment.establishment.Name;
                newEstablishment["Description"] =
                    createEstablishment.establishment.Description;
                newEstablishment["Activate"] =
                    createEstablishment.establishment.Activate;
                newEstablishment["address"] = createEstablishment.establishment.address;
                newEstablishment["zipcode"] = createEstablishment.establishment.zipcode;
                newEstablishment["city"] = createEstablishment.establishment.city;

                const newEsta = data.establishments.filter(function (esta) {
                    return esta;
                });

                const idForUpdateAddedEstablishment = createEstablishment.establishment;

                const newData = {};

                newData["establishments"] = [newEstablishment, ...newEsta];

                cache.writeQuery({
                    query: GET_ESTABLISHMENTS,
                    data: newData,
                });
                edit(idForUpdateAddedEstablishment);
            },
        });
    };

    const [form] = Form.useForm();

    const [editingKey, setEditingKey] = useState("");

    const isEditing = (record) => record.id === editingKey;

    const [activateState, setActivateState] = useState(false);

    const edit = (record) => {
        setActivateState(record.Activate);

        form.setFieldsValue({
            id: record.id,
            Name: "",
            Description: "",
            Activate: activateState,
            Phone: "",
            facebook: "",
            twitter: "",
            instagram: "",
            address: "",
            zipcode: "",
            city: "",
            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const save = async (id) => {
        try {
            const row = await form.validateFields();

            let updatedRow = {};

            updatedRow = {
                id: id,
                Name: formatString(row.Name),
                Description: row.Description,
                Activate: activateState,
                Phone: "",
                facebook: "",
                twitter: "",
                instagram: "",
                address: row.address,
                zipcode: row.zipcode,
                city: row.city,
            };

            updateEstablishment({
                variables: updatedRow,
                onComplete: console.log("UPDATE OK"),
            });

            setEditingKey("");
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const handleActivate = (record) => {
        let activate = !record.Activate;
        console.log(record);
        console.log(activate);

        updateEstablishment({
            variables: {
                id: record.id,
                Name: record.Name,
                Description: record.Description,
                Activate: activate,
                address: record.address,
                zipcode: record.zipcode,
                city: record.city,
            },

            optimisticResponse: {
                __typename: "Mutation",
                updateEstablishment: {
                    id: data.id,
                    __typename: "Establishment",
                    Name: data.Name,
                    Description: data.Description,
                    Activate: data.Activate,
                    address: data.address,
                    zipcode: data.zipcode,
                    city: data.city,
                },
            },
        });
    };

    const columns = [
        {
            title: "",
            dataIndex: "Activate",
            width: "10%",
            editable: false,
            render: (e, record) => (
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={() => handleActivate(record)}
                    defaultChecked={record.Activate}
                />
            ),
        },
        {
            title: "Restaurant",
            dataIndex: "Name",
            width: "20%",
            editable: true,
            ellipsis: { rows: 2, expandable: true, symbol: "+" },
            render: (text, record) => (
                <Tooltip placement="topLeft" title={text}>
                    <Link className="tableLink" to={`/restaurant/${record.id}`}>
                        {text}
                    </Link>
                </Tooltip >
            ),
        },
        {
            title: "Description",
            dataIndex: "Description",
            width: "20%",
            editable: true,
            ellipsis: { rows: 2, expandable: true, symbol: "+" },
            render: (Description) => (
                <Tooltip placement="topLeft" title={Description}>
                    {Description}
                </Tooltip>
            ),
        },
        {
            title: "Adresse",
            dataIndex: "address",
            width: "20%",
            editable: true,
            ellipsis: { rows: 2, expandable: true, symbol: "+" },
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
        },
        {
            title: "Ville",
            dataIndex: "city",
            width: "15%",
            editable: true,
            ellipsis: { rows: 2, expandable: true, symbol: "+" },
            render: (city) => (
                <Tooltip placement="topLeft" title={city}>
                    {city}
                </Tooltip>
            ),
        },
        {
            title: "",
            dataIndex: "Modifier",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a
                            // href="javascript:;"
                            onClick={() => save(record.id)}
                            style={{ marginRight: 8 }}
                        >
                            <SaveFilled />
                        </a>
                        <Popconfirm title="Annuler la modification ?" onConfirm={cancel}>
                            <a
                            // href="javascript:;"
                            >
                                <CloseCircleFilled />
                            </a>
                        </Popconfirm>
                    </span>
                ) : (
                        <a disabled={editingKey !== ""} onClick={() => edit(record)}>
                            <EditFilled />
                        </a>
                    );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "" ? "number" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :( <br />{JSON.stringify(error)}</p>;
    if (!data || !data.establishments) return <p>Empty :(</p>;

    return (
        <div>
            <PageHeader
                style={{ padding: "0 0 24px 0" }}
                className="site-page-header"
                title="Restaurants"
                subTitle="Liste"
            />
            <Button
                onClick={() => handleCreateEstablishments()}
                type="primary"
                style={{
                    marginBottom: 16,
                }}
            >
                Ajouter un restaurant
            </Button>
            <Form form={form} component={false}>
                <Table
                    className="restoTable"
                    loading={loading}
                    rowKey="id"
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={data.establishments}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                    }}
                />
            </Form>
        </div>
    );
};

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Merci d'entrer un ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default Establishments;
