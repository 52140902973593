import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { PageHeader, Image, Button, Card, Drawer, Space } from "antd";
import { ReadOutlined, CoffeeOutlined, TrophyOutlined } from "@ant-design/icons";
import Icon from '@ant-design/icons';
import { UPDATE_MENU } from "./gql_Menus";
import { GET_CATEGORIES } from "../Dishes/gql_Categories";
import { GET_DRINK_TYPES } from "../Drinks/gql_Drinks";
import { GET_WINE_TYPES } from "../Wines/gql_Wines";
import { useHistory, useLocation } from "react-router-dom";
import Dish from "./Dish";
import Drink from "./Drink";
import Wine from "./Wine";

const OneMenuDetails = () => {

    const { general, handleSetGeneral } = useContext(GeneralContext);
    const { establishment } = useContext(EstablishmentContext);

    const lang = general.langue ? general.langue : 'fr';

    const location = useLocation()
    const history = useHistory()

    const [title, setTitle] = useState('Formule')
    const [subTitle, setSubTitle] = useState('de votre restaurant')
    const [price, setPrice] = useState('Prix')
    const [categories, setCategories] = useState([])
    const [esta, setEsta] = useState('©2021 - Safe Menus')
    const [visible, setVisible] = useState(false)
    const [visibleDrink, setVisibleDrink] = useState(false)
    const [visibleWine, setVisibleWine] = useState(false)
    const [allEstaDishes, setAllEstaDishes] = useState([])
    const [allEstaDrinks, setAllEstaDrinks] = useState([])
    const [allEstaWines, setAllEstaWines] = useState([])
    const [menuContent, setMenuContent] = useState(undefined)
    const [drinkContent, setDrinkContent] = useState(undefined)
    const [wineContent, setWineContent] = useState(undefined)
    const [drinkTypes, setDrinkTypes] = useState([])
    const [wineTypes, setWineTypes] = useState([])

    const [getCategories] = useLazyQuery(GET_CATEGORIES, {
        onError: error_gc => console.log("error_gc", JSON.stringify(error_gc)),
        onCompleted: data_gc => createAllEstaDishes(data_gc),
        fetchPolicy: "no-cache",
    });

    const [getDrinkTypes] = useLazyQuery(GET_DRINK_TYPES, {
        onError: error_gdt => console.log("error_gdt", JSON.stringify(error_gdt)),
        onCompleted: data_gdt => createAllEstaDrinks(data_gdt),
        fetchPolicy: "no-cache",
    });

    const [getWineTypes] = useLazyQuery(GET_WINE_TYPES, {
        onError: error_gdt => console.log("error_gdt", JSON.stringify(error_gdt)),
        onCompleted: data_gdt => createAllEstaWines(data_gdt),
        fetchPolicy: "no-cache",
    });

    const [updateMenu] = useMutation(UPDATE_MENU, {
        onError: (error_um) => console.log("error_um :: ", JSON.stringify(error_um)),
        onCompleted: (data_um) => handleRefresh(data_um.updateMenu.menu),
        fetchPolicy: "no-cache",
    });

    const handleRefresh = useCallback((datas) => {
        if (datas) {
            defineMenu(datas)
            defineDrinks(datas)
            defineWines(datas)
            getCategories({ variables: { establishment: establishment.id } });
            getDrinkTypes({ variables: { establishments: establishment.id } });
            getWineTypes({ variables: { establishments: establishment.id } });
        }
    }, [])

    //////////////// tous les plats ////
    //////////////// tous les plats ////
    //////////////// tous les plats ////

    const getPaneName = (panName) => {
        const lang = general.langue ? general.langue : 'fr';
        const tempName = Object.entries(JSON.parse(panName)).find((n, i) => {
            return lang === n[0]
        })
        return tempName[1]
    }

    const createAllEstaDishes = (datas) => {
        const newPanes = datas.categories.map((pane) => {
            return {
                rank: JSON.parse(pane.rank),
                title: getPaneName(pane.name),
                content: pane.dishes.map((dish, i) =>
                    <DishInList
                        categories={categories}
                        cat={pane.id}
                        cats={datas.categories}
                        dish={dish}
                        key={i}
                    />),
                key: pane.id,
            }
        })
        newPanes.sort((a, b) => a.rank - b.rank)
        setAllEstaDishes([...newPanes])
    }

    const DishInList = ({ dish }) => {
        const alreadyOnThisMenus = dish.menus.map(menu => menu.id)
        const check = alreadyOnThisMenus.indexOf(menuContent.id) !== -1 ? true : false
        return dish.active
            ? (
                <Button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={check}
                    onClick={() => handleAddDishToMenu(dish.id)}
                >
                    <span style={{ fontWeight: "500" }}>{dish.name}</span>
                </Button>
            )
            : (
                <Button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={true}
                >
                    {dish.name}
                </Button>
            )
    }

    const handleAddDishToMenu = (id) => {
        const alreadyInMenu = menuContent.dishes.map(d => d.id)
        const zavars = {
            id: menuContent.id,
            dishes: [...alreadyInMenu, id],
        }
        updateMenu({ variables: zavars })
    }

    const handleOnClose = () => {
        setVisible(false)
    }

    const viewAllEstaDishes = () => {
        return allEstaDishes.length > 0
            ? (
                allEstaDishes.map((cat, i) => {

                    return (
                        <div key={i}>
                            <h3>{cat.title}</h3>
                            {cat.content.map(d => d)}
                        </div>
                    )
                })
            )
            : null
    }

    const handleDeleteDish = (e) => {
        const alreadyInMenu = menuContent.dishes.map(d => d.id)
        const index = alreadyInMenu.indexOf(e)
        alreadyInMenu.splice(index, 1);
        const zavars = {
            id: menuContent.id,
            dishes: [...alreadyInMenu],
        }

        if (categories.length < 2) {
            setCategories([])
        }

        updateMenu({ variables: zavars })
    }

    //////////////////////////////////////////////// tous les plats ////
    //////////////////////////////////////////////// tous les plats ////
    //////////////////////////////////////////////// tous les plats ////



    //////////////// toutes les boissons ////
    //////////////// toutes les boissons ////
    //////////////// toutes les boissons ////

    const createAllEstaDrinks = (datas) => {

        const newPanes = datas.drinkTypes.map((pane) => {
            return {
                title: pane.name,
                content: pane.drinks.map((drink, i) =>
                    <DrinkInList
                        drinkTypes={drinkTypes}
                        types={datas.drinkTypes}
                        drink={drink}
                        type={pane.id}
                        key={i}
                    />),
                id: JSON.parse(pane.id),
            }
        })
        newPanes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        setAllEstaDrinks([...newPanes])
    }

    const DrinkInList = ({ drink }) => {

        const alreadyOnThisMenus = drink.menus.map(menu => menu.id)
        const check = alreadyOnThisMenus.indexOf(menuContent.id) !== -1 ? true : false
        return drink.active
            ? (
                <Button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={check}
                    onClick={() => handleAddDrinkToMenu(drink.id)}
                >
                    <span style={{ fontWeight: "500" }}>{drink.name}</span>
                </Button>
            )
            : (
                <Button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={true}
                >
                    {drink.name}
                </Button>
            )
    }

    const handleAddDrinkToMenu = (id) => {
        const alreadyInMenu = drinkContent.drinks.map(d => d.id)
        const zavars = {
            id: drinkContent.id,
            drinks: [...alreadyInMenu, id],
        }
        updateMenu({ variables: zavars })
    }

    const handleOnCloseDrink = () => {
        setVisibleDrink(false)
    }

    const viewAllEstaDrinks = () => {
        return allEstaDrinks.length > 0
            ? (
                allEstaDrinks.map((ed, i) => {
                    return (
                        <div key={i}>
                            <h3>{ed.title}</h3>
                            {ed.content.map(d => d)}
                        </div>
                    )
                })
            )
            : null
    }

    const handleDeleteDrink = (e) => {
        const alreadyInMenu = drinkContent.drinks.map(d => d.id)
        const index = alreadyInMenu.indexOf(e)
        alreadyInMenu.splice(index, 1);
        const zavars = {
            id: drinkContent.id,
            drinks: [...alreadyInMenu],
        }

        if (drinkTypes.length < 2) {
            setDrinkTypes([])
        }

        updateMenu({ variables: zavars })
    }

    //////////////////////////////////////////////// toutes les boissons ////
    //////////////////////////////////////////////// toutes les boissons ////
    //////////////////////////////////////////////// toutes les boissons ////



    //////////////// tous les vins ////
    //////////////// tous les vins ////
    //////////////// tous les vins ////

    const createAllEstaWines = (datas) => {

        const newPanes = datas.wineTypes.map((pane) => {

            return {
                title: pane.name,
                content: pane.wines.map((wine, i) =>
                    <WineInList
                        wineTypes={wineTypes}
                        types={datas.wineTypes}
                        wine={wine}
                        type={pane.id}
                        key={i}
                    />),
                id: JSON.parse(pane.id),
            }
        })
        newPanes.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        // console.log("newPanes :: ", newPanes)
        setAllEstaWines([...newPanes])
    }

    const WineInList = ({ wine }) => {

        const alreadyOnThisMenus = wine.menus.map(menu => menu.id)
        const check = alreadyOnThisMenus.indexOf(menuContent.id) !== -1 ? true : false
        return wine.active
            ? (
                <Button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={check}
                    onClick={() => handleAddWineToMenu(wine.id)}
                >
                    <span style={{ fontWeight: "500" }}>{wine.name}</span>
                </Button>
            )
            : (
                <Button
                    type="link"
                    style={{ width: "100%", textAlign: "left", margin: "0 0 0.5em 0" }}
                    disabled={true}
                >
                    {wine.name}
                </Button>
            )
    }

    const handleAddWineToMenu = (id) => {
        const alreadyInMenu = wineContent.wines.map(d => d.id)
        const zavars = {
            id: wineContent.id,
            wines: [...alreadyInMenu, id],
        }
        updateMenu({ variables: zavars })
    }

    const handleOnCloseWine = () => {
        setVisibleWine(false)
    }

    const viewAllEstaWines = () => {
        return allEstaWines.length > 0
            ? (
                allEstaWines.map((ew, i) => {
                    return (
                        <div key={i}>
                            <h3>{ew.title}</h3>
                            {ew.content.map(d => d)}
                        </div>
                    )
                })
            )
            : null
    }

    const handleDeleteWine = (e) => {
        const alreadyInMenu = wineContent.wines.map(w => w.id)
        const index = alreadyInMenu.indexOf(e)
        alreadyInMenu.splice(index, 1);
        const zavars = {
            id: wineContent.id,
            wines: [...alreadyInMenu],
        }

        if (wineTypes.length < 2) {
            setWineTypes([])
        }

        updateMenu({ variables: zavars })
    }

    //////////////////////////////////////////////// tous les vins ////
    //////////////////////////////////////////////// tous les vins ////
    //////////////////////////////////////////////// tous les vins ////


    const handleBackToMenus = () => {
        history.push({ pathname: "/formules", menu: location.menu })
        console.log("general :: ", general)
    }

    useEffect(() => {

        if (location.menu) {
            defineMenu(location.menu)
            defineDrinks(location.menu)
            defineWines(location.menu)
            handleSetGeneral({
                ...general,
                localMenuContent: location.menu,
                page: "/formuledetails",
            })
        } else {
            const localMenuContent = general.localMenuContent
            if (localMenuContent !== undefined) {
                defineMenu(JSON.parse(localMenuContent))
                defineDrinks(JSON.parse(localMenuContent))
                defineWines(JSON.parse(localMenuContent))
                handleSetGeneral({
                    ...general,
                    page: "/formuledetails",
                })
            }
        }
        if (location.establishment) {
            setEsta(`- ${location.establishment.Name} -`)
        }
        if (establishment) {
            getCategories({ variables: { establishment: establishment.id } });
            getDrinkTypes({ variables: { establishments: establishment.id } });
            getWineTypes({ variables: { establishments: establishment.id } });
        }

    }, []);

    useEffect(() => {
        if (menuContent !== undefined) {
            defineMenu(menuContent)
        }
    }, [lang]);

    const defineMenu = (vars) => {
        setTitle(vars.name)
        setSubTitle(vars.description)
        setPrice(vars.price)
        let newCategories = [];
        let tempCategories = [];
        let temp2Categories = [];
        vars.dishes.map(cat => {
            if (cat.active) {
                const catRaw = cat.categories[0].name
                const catRank = cat.categories[0].rank
                const catTrad = Object.entries(JSON.parse(catRaw))
                    .find(cat => {
                        return (cat[0] === lang)
                            ? tempCategories.push(JSON.stringify([cat[1], catRank]))
                            : undefined
                    })
                tempCategories.map(cat => {
                    return temp2Categories.indexOf(cat) === -1
                        ? temp2Categories.push(cat)
                        : null
                })
                newCategories = temp2Categories.map(cat => JSON.parse(cat))
                newCategories.sort((a, b) => a[1] - b[1])
                setCategories(newCategories)
            }
        })

        setMenuContent(vars)
    }

    const defineDrinks = (vars) => {
        let tempBoissons = []
        let uniq = []
        vars.drinks.map((boisson, i) => {
            if (boisson.active) {
                const boissonType = boisson.drink_types[0].name
                tempBoissons.push(boissonType)
                uniq = [...new Set(tempBoissons)];
                if (i + 1 === vars.drinks.length) {
                    uniq.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
                    setDrinkTypes(uniq)
                }
            }
        })
        setDrinkContent(vars)
    }

    const defineWines = (vars) => {
        let tempVins = []
        let uniq = []
        vars.wines.map((vin, i) => {
            if (vin.active) {
                const vinType = vin.wine_types[0].name
                tempVins.push(vinType)
                uniq = [...new Set(tempVins)];
                if (i + 1 === vars.wines.length) {
                    uniq.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
                    setWineTypes(uniq)
                }
            }
        })
        setWineContent(vars)
    }

    const getMenuContent = () => {
        if (categories.length > 0) {
            return (
                categories.map((cat, i) => {
                    return (
                        <Card bordered={false} key={i}>
                            <Space style={{ background: "green", width: "100%", textAlign: "center" }}>
                                <h2 className="catTitleInCard"><ReadOutlined /> {cat[0]}</h2>
                            </Space>
                            {menuContent.dishes.map((dish, i) => {
                                const tempCat = Object.entries(JSON.parse(dish.categories[0].name))
                                const tempCat2 = tempCat.filter(tc => lang === tc[0] ? tc : null)
                                const transCat = tempCat2.map(tc => tc[1])
                                return transCat[0] === cat[0]
                                    ? <Dish key={i} dish={dish} handleDeleteDish={handleDeleteDish} />
                                    : null
                            })}
                        </Card>
                    )
                })
            )
        }
    }

    const getDrinkContent = () => {
        if (drinkTypes.length > 0) {
            return (
                drinkTypes.map((dt, i) => {
                    return (
                        <Card bordered={false} key={i}>
                            <Space style={{ background: "green", width: "100%", textAlign: "center" }}>
                                <h2 className="catTitleInCard"><CoffeeOutlined /> {dt}</h2>
                            </Space>
                            {drinkContent.drinks.map((drink, i) => {
                                return drink.drink_types[0].name === dt
                                    ? <Drink key={i} drink={drink} handleDeleteDrink={handleDeleteDrink} />
                                    : null
                            })}
                        </Card>
                    )
                })
            )
        }
    }

    const getWineContent = () => {
        if (wineTypes.length > 0) {
            return (
                wineTypes.map((dt, i) => {
                    return (
                        <Card bordered={false} key={i}>
                            <Space style={{ background: "green", width: "100%", textAlign: "center" }}>
                                <h2 className="catTitleInCard"><TrophyOutlined /> {dt}</h2>
                            </Space>
                            {wineContent.wines.map((wine, i) => {
                                return wine.wine_types[0].name === dt
                                    ? <Wine key={i} wine={wine} handleDeleteWine={handleDeleteWine} />
                                    : null
                            })}
                        </Card>
                    )
                })
            )
        }
    }

    //////////////////////////////////// return ////////
    //////////////////////////////////// return ////////

    return (
        <div>

            <Drawer // pour les plats
                title="Ajouter un plat"
                placement="right"
                closable={false}
                onClose={() => handleOnClose()}
                visible={visible}
                getContainer={false}
                width="350px"
                style={{ position: 'fixed' }}
            >
                {viewAllEstaDishes()}
            </Drawer>

            <Drawer // pour les boissons
                title="Ajouter une boisson"
                placement="right"
                closable={false}
                onClose={() => handleOnCloseDrink()}
                visible={visibleDrink}
                getContainer={false}
                width="350px"
                style={{ position: 'fixed' }}
            >
                {viewAllEstaDrinks()}
            </Drawer>

            <Drawer // pour les vins
                title="Ajouter une référence"
                placement="right"
                closable={false}
                onClose={() => handleOnCloseWine()}
                visible={visibleWine}
                getContainer={false}
                width="350px"
                style={{ position: 'fixed' }}
            >
                {viewAllEstaWines()}
            </Drawer>

            <PageHeader
                style={{ padding: "0 0 24px 0" }}
                className="site-page-header"
                title={title}
                subTitle={`${subTitle} - ${price} €`}
            />

            <div className="buttons-top">

                <Button
                    onClick={() => handleBackToMenus()}
                    type="primary"
                    ghost
                    style={{ margin: "0 1em 16px 0" }}
                >
                    Retour
                </Button>

                <Button style={{ margin: "0 1em 16px 0" }} type="primary" onClick={() => setVisible(true)}>
                    + Plats
                </Button>

                <Button style={{ margin: "0 1em 16px 0" }} type="primary" onClick={() => setVisibleDrink(true)}>
                    + Boissons
                </Button>

                <Button style={{ margin: "0 1em 16px 0" }} type="primary" onClick={() => setVisibleWine(true)}>
                    + Vins
                </Button>

            </div>

            <Card
                style={{
                    borderColor: "#2ec2ca",
                    padding: "10px 0"
                }}
                className="menuContent in-menu-details-00"
            >
                {getMenuContent()}
                {getDrinkContent()}
                {getWineContent()}
                <div style={{
                    color: "#888888",
                    padding: "1em 0",
                    margin: "0",
                    width: "100%"
                }}
                    align='center'>
                    {esta}
                </div>

            </Card>
        </div>
    );
};

export default OneMenuDetails;
