import React, { useState } from "react";
import { Upload, Button, Card } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import {
  DELETE_FILE,
  UPDATE_ESTABLISHMENT_LOGO,
  GET_ESTABLISHMENT,
} from "./gql_Establishments";

const GestionLogo = ({ esta }) => {
    
    const [updateEstablishmentLogo, { loading: loading_uel, error: error_uel, data: data_uel }] = useMutation(UPDATE_ESTABLISHMENT_LOGO,{
        onError: (error_uel) => console.log("error_uel :: ", JSON.stringify(error_uel)),
        onCompleted: (data_uel) => console.log("data_uel :: ", data_uel),
        refetchQueries: [
        {
          query: GET_ESTABLISHMENT,
          variables: { id: esta.id },
          fetchPolicies: 'no-cache'
        },
      ],
    }
  );

  const [deleteFile] = useMutation(DELETE_FILE);

  let vars = {};

  const fireFry = () => {
    setTimeout(function () {
      //   console.log(vars);
      updateEstablishmentLogo({
        variables: vars,
      });
    }, 1000);
  };

  const deleteImage = () => {
    deleteFile({
      variables: { id: esta.logo.id },
      onCompleted: (data) => {
        // console.log(data);
      },
      refetchQueries: [
        {
          query: GET_ESTABLISHMENT,
          variables: { id: esta.id },
        },
      ],
    });
  };

  let [fileList, setFileList] = useState([]);

  let i = 0;

  const handleUpload = ({ fileList }) => {
    if (i < 1) {
      setFileList([fileList[0].originFileObj]);

      vars = {
        id: esta.id,
        file: fileList[0].originFileObj,
        refId: esta.id,
        ref: "Establishment",
        field: "logo",
      };

      fireFry();
      i++;
    }
  };

  if (error_uel) return <p>Error...</p>;
  if (loading_uel) return <p>Loading...</p>;

  return (
      <Card 
        className="in-gestion-logo-00"
        style={{
          padding: "1em 0",
          margin: "1em 0 0 0",
          width: "100%"
      }}>
      {esta.logo ? (
        <Button danger onClick={() => deleteImage()}>Supprimer le visuel</Button>
      ) : (
        <Upload
          listType="picture"
          fileList={fileList.name}
          onChange={(fileList) => handleUpload(fileList)}
        >
            <Button className="in-gestion-logo-01" icon={<UploadOutlined />}>Ajouter un visuel</Button>
        </Upload>
      )}
    </Card>
  );
};

export default GestionLogo;
