import { gql } from "@apollo/client"


export const GET_MENUS = gql`
    query menus($establishment: [ID!]) {
        menus(where: { establishment: $establishment }) {
            id
            name
            description
            price
            active
            dishes {
                id
                name
                active
                img {
                    formats
                }
                price
                allergenes
                origine
                side_dish
                description
                Ingredients
                rank
                categories {
                    id
                    name
                    rank
                }
            }
            drinks {
                id
                name
                specs {
                    id
                    size
                    price
                    priceHH
                    designation
                }
                description
                active
                drink_types {
                    id
                    name
                }
            }
            wines {
                id
                name
                country
                year
                region
                designation
                producer
                description
                organic
                specs {
                    id
                    size
                    price
                    priceHH
                    designation
                }
                active
                wine_types {
                    id
                    name
                }
            }
        }
    }
`;

export const CREATE_MENU = gql`
    mutation createMenu(
        $name: String
        $description: String
        $active: Boolean
        $price: String
        $dishes: [ID]
        $drinks: [ID]
        $wines: [ID]
        $establishment: ID
    ) {
        createMenu(
            input: {
                data: {
                    name: $name
                    description: $description
                    active: $active
                    price: $price
                    dishes: $dishes
                    drinks: $drinks
                    wines: $wines
                    establishment: $establishment
                }
            }
        ) {
            menu {
                id
                name
                active
                price
                description
                dishes{
                    id
                }
                drinks{
                    id
                }
                wines{
                    id
                }
                establishment{
                    id
                }
            }
        }
    }
`;

export const UPDATE_MENU = gql`
    mutation updateMenu(
            $id: ID! 
            $active: Boolean
            $price: String 
            $description: String 
            $name: String 
            $dishes: [ID]
            $drinks: [ID]
            $wines: [ID]
        ) {
        updateMenu(
            input: {
                where: { id: $id }
                data: { 
                    active: $active
                    price: $price 
                    description: $description 
                    name: $name 
                    dishes: $dishes 
                    drinks: $drinks 
                    wines: $wines 
                }
            }
        ) {
            menu {
                id
                name
                active
                price
                description
                drinks {
                    id
                    name
                    specs {
                        id
                        size
                        price
                        priceHH
                        designation
                    }
                    description
                    active
                    drink_types {
                        id
                        name
                    }
                }
                wines {
                    id
                    name
                    country
                    year
                    region
                    designation
                    producer
                    description
                    organic
                    specs {
                        id
                        size
                        price
                        priceHH
                        designation
                    }
                    active
                    wine_types {
                        id
                        name
                    }
                }
                dishes {
                    id
                    name
                    active
                    img {
                        formats
                    }
                    price
                    allergenes
                    origine
                    side_dish
                    description
                    Ingredients
                    rank
                    categories {
                        id
                        name
                        rank
                    }
                }
            }
        }
    }
`;

export const DELETE_MENU = gql`
    mutation deleteMenu($id: ID!) {
        deleteMenu(input: { where: { id: $id } }) {
            menu {
                id
                name
            }
        }
    }
`;