import React, { useState, useContext } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { AUTH_TOKEN } from "../../constants";
import { UserContext } from "../../Contexts/UserContext";
import { GET_USER, LOGIN_MUTATION } from "../../Components/Users/gql_Users";
import { useHistory } from "react-router-dom";

import {
    Form,
    Input,
    Button,
    Checkbox,
    Space,
    Layout,
    Card,
} from "antd";

const { Content } = Layout;

const AuthLogin = () => {
    //////////////////////// LOGIN ///////

    const history = useHistory();

    const { handleSetUser } = useContext(UserContext);

    const [getUser, { error: error_gu, data: data_gu }] = useLazyQuery(GET_USER, {
        onCompleted: (data_gu) => handleSetUser(data_gu.user),
    });

    const [formState, setFormState] = useState({
        email: "",
        password: "",
    });

    const onLogin = (login) => {
        getUser({
            variables: { id: login.user.id },
        });
        localStorage.setItem(AUTH_TOKEN, login.jwt);
    }

    const restart = () => {
        localStorage.clear()
        history.push("/")
    }

    const [login, { error: error_l }] = useMutation(LOGIN_MUTATION, {
        variables: {
            identifier: formState.email,
            password: formState.password,
        },
        onCompleted: ({ login }) => onLogin(login),
        onError: (error_l) => restart(),
    });

    const tryLogin = () => {
        if (formState.email && formState.password) {
            login()
        } else {
        }
    }

    if (error_gu) return <p>error ... {JSON.stringify(error_gu)}</p>;

    return (
        <Content style={{ padding: "0 8vw" }}>
            <Layout style={{ padding: "20vh 0" }} className="layout-login">
                <Space align="center" style={{ width: "100%" }}>
                    <Card align="center" bordered={false} style={{ width: "100%" }}>
                        <Space direction="vertical" className="login">
                            <img
                                style={{ padding: "3vh 0" }}
                                width={240}
                                src="/static/media/logo.7273fb70.svg"
                            />
                            <Form
                                name="loginForm"
                                initialValues={{
                                    remember: true,
                                }}
                            >
                                <Form.Item
                                    label="Utilisateur"
                                    name="Email"
                                    onChange={(e) => {
                                        setFormState({
                                            ...formState,
                                            email: e.target.value,
                                        });
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Entrer votre adresse email",
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    onChange={(e) => {
                                        setFormState({
                                            ...formState,
                                            password: e.target.value,
                                        });
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Entrez votre mot de pass",
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Se rappeler de moi</Checkbox>
                                </Form.Item>

                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        onClick={() => tryLogin()}
                                    >
                                        Connexion
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Space>
                    </Card>
                </Space>
            </Layout>
        </Content>
    );
};

export default AuthLogin;
