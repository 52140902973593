import React, { useState, useContext, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { PageHeader, Button, Empty } from "antd";
import { GET_CARDS, CREATE_CARD } from "./gql_Cards";
import OneCard from "./OneCard";


const Cards = () => {

    const [esta, setEsta] = useState('')

    const { general, handleSetGeneral, showMenu, menuVisible } = useContext(GeneralContext);
    const { establishment } = useContext(EstablishmentContext);

    // console.log("establishment :: ", establishment)

    const [cards, { error: error_gc, data: data_gc }] = useLazyQuery(GET_CARDS, {
        onError: (error_gc) => console.log("error_gc", JSON.stringify(error_gc)),
        onCompleted: (data_gc) => listCards(data_gc),
        fetchPolicy: "no-cache",
    });

    const [createCard, { error: error_cc, data: data_cc }] = useMutation(CREATE_CARD, {
        onError: (error_cc) => console.log("error_cc :: ", JSON.stringify(error_cc)),
        onCompleted: (data_cc) => cards({ variables: { establishments: establishment.id } }),
        fetchPolicy: "no-cache",
    });

    const [allCards, setAllcards] = useState([])
    const [plural, setPlural] = useState(false);

    useEffect(() => {
        handleSetGeneral({ ...general, page: "/cartes" });

        if (establishment) {
            cards({ variables: { establishments: establishment.id } });
            setEsta(`- ${establishment.Name} -`)
        }
        
        if (menuVisible === true){
            showMenu()
        }
        
    }, []);

    const listCards = (datas) => {
        if (datas.cards.length > 1) {
            setPlural(true);
        }
        const newKidsOnTheBlock = datas.cards.map(c=>c)
        newKidsOnTheBlock.sort((a, b) => a.id - b.id)
        setAllcards(newKidsOnTheBlock)
    }

    const handleCreateCard = () => {
        const newCard = {
            name: 'NouvelleCarte',
            description: '',
            active: false,
            dishes: [],
            drinks: [],
            wines: [],
            establishments: establishment.id,
        };

        createCard({ variables: newCard })
    }

    if (!establishment) {
        return <Empty className="emptyty" description="sélectionner un restaurant" />;
    }

    return (
        <div>
            <PageHeader
                style={{ padding: "0 0 24px 0" }}
                className="site-page-header"
                title={plural ? "Les Cartes" : "La Carte"}
                subTitle="de votre restaurant"
            />
            <Button onClick={() => handleCreateCard()} type="primary" style={{ marginBottom: 16 }}>
                Ajouter une carte
            </Button>
            {
                allCards.length > 0
                    ? allCards.map((card,i) => <OneCard 
                        key={i}    
                        esta={establishment} 
                        card={card} 
                        listCards={listCards}
                    />)
                    : null
            }
            <div style={{
                color: "#888888",
                padding: "1em 0",
                margin: "0",
                width: "100%"
            }}
                align='center'>
                {esta}
            </div>
        </div>
    )
}

export default Cards;
