import { gql } from "@apollo/client";

export const DELETE_CATEGORY = gql`
    mutation deleteCategory($id: ID!) {
    deleteCategory(input: { where: { id: $id } }) {
    category {
        id
    }
  }
}
`;

export const UPDATE_CATEGORY = gql`
mutation updateCategory($id: ID!, $name: String, $description: String, $rank: String) {
  updateCategory(input: { where: { id: $id }, data: { name: $name, description: $description, rank: $rank } }) {
    category {
    id
    rank
    name
    description
    dishes {
        id
        name
        active
        img {
                id
                name
            }
        price
        allergenes
        origine
        side_dish
        description
        Ingredients
        cards {
                id
            }
        menus {
                id
            }
        }
    establishment {
        id
        Name
        }  
    }
  }
}
`;


export const GET_CATEGORIES = gql`
query categories($establishment: ID) {
  categories(where: { establishment: $establishment }) {
    id
    rank
    name
    description
    dishes (sort: "id:asc") {
        id
        name
        active
        folded
        rank
        img {
            id
            created_at
            updated_at
            name
            alternativeText
            caption
            width
            height
            formats
            size
            url
            previewUrl
        }
        price
        allergenes
        origine
        side_dish
        description
        Ingredients
        cards {
                id
            }
        menus {
                id
            }
        }
    establishment {
        id
        Name
        }  
    }
}
`;

export const CREATE_CATEGORY = gql`
mutation createCategory (
    $name: String,
    $description: String,
    $establishment: ID,
    $rank: String,
    ) {
    createCategory(input: { 
        data: { 
            name: $name, 
            description: $description,
            establishment: $establishment 
            rank: $rank
        } 
    }) {
    category {
        id
        rank
        name
        description
        establishment {
                id
                Name
            }
        }
    }
}
`;