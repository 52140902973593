import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext({
    user: {},
    setUser: () => { },
});

const exist = () => {
    const localUser = JSON.parse(localStorage.getItem('user'))
    if (localUser !== null) {
        return localUser
    } else {
        return undefined
    }
}

export const UserProvider = ({ children }) => {

    const [user, setUser] = useState(exist());

    const handleSetUser = (user) => {
        setUser(user);
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(user));
    }

    return (
        <UserContext.Provider value={{ user, handleSetUser, setUser }}>
            {children}
        </UserContext.Provider>
    )
}