import { gql } from "@apollo/client";

export const GET_DRINK_TYPES = gql`
    query drinkTypes($establishments: ID) {
        drinkTypes(where: { establishments: $establishments }) {
            id
            name
            drinks {
                id
                active
                name
                description
                cards {
                    id
                }
                menus {
                    id
                }
                specs {
                    id
                    size
                    price
                    priceHH
                    designation
                }
            }
            establishments {
                id
            }
        }
    }
`;

export const UPDATE_DRINK = gql`
    mutation updateDrink($id: ID!
     $drink_types: [ID]
     $specs: [editComponentContainersContainerInput]
     $description: String
     $name: String
     $active: Boolean
    ) {
        updateDrink(
            input: { 
                where: { id: $id }, 
                data: { 
                    drink_types: $drink_types
                    specs: $specs
                    description: $description
                    name: $name 
                    active: $active
                } }
        ) {
            drink {
                id
                name
                drink_types {
                    id
                    name
                }
            }
        }
    }
`;

export const CREATE_DRINK = gql`
    mutation createDrink(
        $name: String
        $drink_types: [ID]
        $specs: [ComponentContainersContainerInput]
        $description: String
        $active: Boolean
    ) {
        createDrink(
            input: {
                data: {
                    name: $name
                    drink_types: $drink_types
                    specs: $specs
                    description: $description
                    active: $active
                }
            }
        ) {
            drink {
                id
                name
                description
                active
                drink_types {
                    id
                    name
                }
                specs {
                    id
                    size
                    price
                    priceHH
                    designation
                }
            }
        }
    }
`;

export const DELETE_DRINK = gql`
    mutation deleteDrink($id: ID!) {
        deleteDrink(input: { where: { id: $id } }) {
            drink {
                id
                name
            }
        }
    }
`;
