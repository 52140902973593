import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Contexts/UserContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { useMutation } from "@apollo/client";
import { Space, notification, Button, Card, PageHeader, Collapse, Input, Typography } from "antd";
import { debounce } from "lodash";
import { Link, useHistory } from "react-router-dom";

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import {
    GET_USER,
    UPDATE_USER,
} from "../../Components/Users/gql_Users";

const { Text } = Typography;
const { Panel } = Collapse;

const Settings = () => {

    const history = useHistory();

    const { user, handleSetUser } = useContext(UserContext);
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext);
    const { general, handleSetGeneral, showMenu, menuVisible } = useContext(GeneralContext);

    useEffect(() => {
        handleSetGeneral({ ...general, page: "/settings" });

        if (menuVisible === true) {
            showMenu()
        }

    }, []);

    const handleDisconnect = async () => {
        let time = setTimeout(() => handleSetGeneral({ ...general, page: "/" }),500)
        let time2 = setTimeout(() => localStorage.clear(),1000)
        let time3 = setTimeout(() => document.location.reload(),1500)
        // await handleSetGeneral({ ...general, page: "/" });
        // localStorage.clear();
        // document.location.reload()
    }

    const notify = (type, what) => {
        notification[type]({
            message: 'mise à jour réussie',
            description: what,
        });
    };

    const waitin = 1200;

    const [updateUser, { error }] = useMutation(UPDATE_USER, {
        onCompleted: (data) => {
            notify('success', `Mise à jour réussie`);
            handleSetUser(data.updateUser.user);
        },
        onError: (error) => console.log("error", JSON.stringify(error)),
    });

    const [form, setForm] = useState({
        pass01: '',
        pass02: '',
        ok: true
    });

    const handleEnregistrer = () => {
        if (form.pass01 === form.pass02) {
            setForm({ ...form, ok: true })
            updateUser({
                variables: {
                    id: user.id,
                    email: user.email,
                    username: user.email,
                    lastname: user.lastname,
                    firstname: user.firstname,
                    password: form.pass02,
                }
            });
            console.log(form);
        } else {
            setForm({ ...form, ok: false })
            console.log(form);
        }
    }

    return (
        <div>
            {user.role.id === "3"
                ? (<PageHeader
                    style={{ padding: "0px 0 24px 0", top: "-4px" }}
                    className="site-page-header"
                    title="Paramètres"
                    subTitle="Utilisateur"
                    extra={[
                        <Button type="primary" onClick={() => { history.push("/userssettings") }}>
                            Gestion des utilisateurs
                        </Button>,
                        <Button danger onClick={() => { handleDisconnect() }}>se déconnecter</Button>,
                    ]}
                />)
                : (<PageHeader
                    style={{ padding: "0px 0 24px 0", top: "-4px" }}
                    className="site-page-header"
                    title="Paramètres"
                    subTitle="Utilisateur"
                    extra={[
                        <Button danger onClick={() => { handleDisconnect() }}>se déconnecter</Button>,
                    ]}
                />)
            }

            <Card style={{ width: "100%" }}>
                <Space
                    direction="vertical"
                    align="start"
                    style={{ width: "100%", padding:"1em 0" }}
                >
                    <Input.Group>
                        <Input
                            onChange={debounce((e) => updateUser({
                                variables: {
                                    id: user.id,
                                    email: user.email,
                                    username: user.email,
                                    lastname: user.lastname,
                                    firstname: e.target.value,
                                }
                            }), waitin)}
                            name="firstname"
                            className="restoDetailsTitle"
                            bordered={false}
                            defaultValue={user.firstname}
                        />
                        <Input
                            onChange={debounce((e) => updateUser({
                                variables: {
                                    id: user.id,
                                    email: user.email,
                                    username: user.email,
                                    lastname: e.target.value,
                                    firstname: user.firstname,
                                }
                            }), waitin)}
                            name="lastname"
                            className="restoDetailsTitle"
                            bordered={false}
                            defaultValue={user.lastname}
                        />
                    </Input.Group>
                    <Input
                        onChange={debounce((e) => updateUser({
                            variables: {
                                id: user.id,
                                email: e.target.value,
                                username: e.target.value,
                                lastname: user.lastname,
                                firstname: user.firstname,
                            }
                        }), waitin)}
                        name="email"
                        bordered={false}
                        className="restoDetailsPhone"
                        defaultValue={user.email}
                        style={{ width: "100%" }}
                    />
                    {establishment
                        ? (<Text>Restaurant:{' '}
                            <Link to={`/restaurant/${establishment.id}`}>
                                {establishment.Name}
                            </Link>
                        </Text>)
                        : (<Text>
                            <Link to={`/restaurants/`}>
                                restaurants
                            </Link>
                        </Text>)
                    }

                    <Collapse ghost>
                        <Panel header="Modifier mon mot de pass" key="0">
                            <Space direction="vertical">
                                {form.ok ? null : 'La vérification a échouée!'}
                                <Input.Password
                                    placeholder="nouveau mot de pass"
                                    onChange={(e) => setForm({ ...form, pass01: e.target.value })}
                                />
                                <Input.Password
                                    placeholder="nouveau mot de pass"
                                    onChange={(e) => setForm({ ...form, pass02: e.target.value })}
                                    iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                                <Button
                                    type="primary"
                                    onClick={() => handleEnregistrer()}
                                >
                                    Enregistrer
                                </Button>
                            </Space>
                        </Panel>
                    </Collapse>
                </Space>
            </Card>
        </div>
    )

}

export default Settings;
