import React, { useContext, useMemo, useState, useEffect } from "react";
import { UserContext } from "../../Contexts/UserContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { useQuery, useMutation } from "@apollo/client";
import {
    UPDATE_ESTABLISHMENT_NAME,
    UPDATE_ESTABLISHMENT_ADDRESS,
    UPDATE_ESTABLISHMENT_ZIPCODE,
    UPDATE_ESTABLISHMENT_CITY,
    UPDATE_ESTABLISHMENT_PHONE,
    UPDATE_ESTABLISHMENT_DESCRIPTION,
    UPDATE_ESTABLISHMENT_TWITTER,
    UPDATE_ESTABLISHMENT_FACEBOOK,
    UPDATE_ESTABLISHMENT_INSTAGRAM,
    GET_ESTABLISHMENT,
    DELETE_ESTABLISHMENT,
} from "./gql_Establishments";

import { GET_ESTABLISHMENTS } from "./gql_Establishments";

import { useParams, useHistory } from "react-router-dom";
import { PageHeader, Space, notification, Popconfirm, Button, Card, Row, Col, Image, Input } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Owner from "../Users/Owner";
import GestionLogo from "./GestionLogo";
import QRCode from "qrcode.react";
import logo from "../../Slogo.svg";
import { formatString, SERVER_URL } from "../../constants";

import { debounce } from "lodash";

const { TextArea } = Input;

const Establishment = ({ propId, role }) => {

    //////////////////////////////////////////////////////// Establishment ////

    let { id } = useParams();

    if (!id) id = propId;

    const history = useHistory();

    const { user, handleSetUser } = useContext(UserContext);
    const { general, handleSetGeneral, showMenu, menuVisible } = useContext(GeneralContext);
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext);

    const notify = (type, what) => {
        notification[type]({
            message: 'mise à jour réussie',
            description: what,
        });
    };

    const { loading, error, data } = useQuery(GET_ESTABLISHMENT, {
        variables: { id: id },
        fetchPolicy: "network-only",
        onCompleted: (data) => (
            handleSetEstablishment(data.establishment),
            // console.log("data :: ", data),
            handleSetGeneral({ ...general, page: "/restaurant/"+data.establishment.id})
        ),
    });

    const [updateEstablishmentName] = useMutation(UPDATE_ESTABLISHMENT_NAME, {
        onCompleted: (data) => {
            notify(
                'success',
                `Nom : ${data.updateEstablishment.establishment.Name}`
            );
            if (role === "1")
                handleSetUser({ ...user, establishments: [{ ...user.establishments[0], Name: data.updateEstablishment.establishment.Name }] });
        },
    });

    const [updateEstablishmentAddress] = useMutation(
        UPDATE_ESTABLISHMENT_ADDRESS,
        {
            onCompleted: (data) => notify(
                'success',
                `Adresse : ${data.updateEstablishment.establishment.address}`
            ),
        }
    );

    const [updateEstablishmentZipcode] = useMutation(
        UPDATE_ESTABLISHMENT_ZIPCODE,
        {
            onCompleted: (data) => notify(
                'success',
                `Code Postal : ${data.updateEstablishment.establishment.zipcode}`
            ),
        }
    );

    const [updateEstablishmentCity] = useMutation(UPDATE_ESTABLISHMENT_CITY, {
        onCompleted: (data) => notify(
            'success',
            `Ville : ${data.updateEstablishment.establishment.city}`
        ),
    });

    const [updateEstablishmentPhone] = useMutation(UPDATE_ESTABLISHMENT_PHONE, {
        onCompleted: (data) => notify(
            'success',
            `Téléphone : ${data.updateEstablishment.establishment.Phone}`
        ),
    });

    const [updateEstablishmentDescription] = useMutation(
        UPDATE_ESTABLISHMENT_DESCRIPTION,
        {
            onCompleted: (data) => notify(
                'success',
                `Description : ${data.updateEstablishment.establishment.Description}`
            ),
        }
    );

    const [updateEstablishmentTwitter] = useMutation(
        UPDATE_ESTABLISHMENT_TWITTER,
        {
            onCompleted: (data) => notify(
                'success',
                `Compte Twitter : ${data.updateEstablishment.establishment.twitter}`
            ),
        }
    );

    const [updateEstablishmentFacebook] = useMutation(
        UPDATE_ESTABLISHMENT_FACEBOOK,
        {
            onCompleted: (data) => notify(
                'success',
                `Compte Facebook : ${data.updateEstablishment.establishment.facebook}`
            ),
        }
    );

    const [updateEstablishmentInstagram] = useMutation(
        UPDATE_ESTABLISHMENT_INSTAGRAM,
        {
            onCompleted: (data) => notify(
                'success',
                `Compte Instagram : ${data.updateEstablishment.establishment.instagram}`
            ),
        }
    );

    const [deleteEstablishment, { error: error_de, data: data_de }] = useMutation(
        DELETE_ESTABLISHMENT,
        {
            onCompleted: data_de => (handleSetEstablishment(undefined), localStorage.removeItem('establishment'), localStorage.removeItem('establishments'), handleSetGeneral({ ...general, page: "/restaurants" })),
            onError: error_de => console.log("error_de :: ", JSON.stringify(error_de)),
            refetchQueries: [{
                query: GET_ESTABLISHMENTS,
                variables: { id: id },
                fetchPolicies: 'no-cache'
            }],
        }
    );

    const waitin = 1200;

    const updateEsta = (e) => {
        switch (e.target.name) {
            case "Name":
                updateEstablishmentName({
                    variables: { id: id, Name: formatString(e.target.value) },
                });
                break;
            case "address":
                updateEstablishmentAddress({
                    variables: { id: id, address: e.target.value },
                });
                break;
            case "zipcode":
                updateEstablishmentZipcode({
                    variables: { id: id, zipcode: e.target.value },
                });
                break;
            case "city":
                updateEstablishmentCity({
                    variables: { id: id, city: e.target.value },
                });
                break;
            case "Phone":
                updateEstablishmentPhone({
                    variables: { id: id, Phone: e.target.value },
                });
                break;
            case "Description":
                updateEstablishmentDescription({
                    variables: { id: id, Description: e.target.value },
                });
                break;
            case "twitter":
                updateEstablishmentTwitter({
                    variables: { id: id, twitter: e.target.value },
                });
                break;
            case "facebook":
                updateEstablishmentFacebook({
                    variables: { id: id, facebook: e.target.value },
                });
                break;
            case "instagram":
                updateEstablishmentInstagram({
                    variables: { id: id, instagram: e.target.value },
                });
                break;

            default:
                console.log(e.target.name, e.target.value);
                break;
        }
    };

    const downloadQR = (establishmentName) => {
        const canvas = document.getElementById("leQr");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${establishmentName}[QRcode].png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    useEffect(() => {
        if (menuVisible === true) {
            showMenu()
        }
    }, [])

    if (loading) return <p>loading...</p>;
    if (error) return <p>Oups...{JSON.stringify(error)}</p>;

    return (
        <form>
            <PageHeader
                style={{ padding: "0 0 24px 0" }}
                className="site-page-header"
                title="Restaurant"
                subTitle="Informations"
            />
            <div className="site-card-wrapper">
                {/* {JSON.stringify(data.establishment.logo.formats)} */}
                <Row gutter={24} className="img">
                    <Col span={8}>
                        <Card
                            bordered={false}
                            cover={
                                data.establishment.logo !== null ? (
                                    <Image
                                        className="img-establishment"
                                        style={{
                                            position: "relative",
                                            zIndex: 10,
                                            objectFit: 'cover',
                                            width: '22vw',
                                            height: '22vw',
                                            borderRadius: "2%"
                                        }}
                                        src={`https://${SERVER_URL}${data.establishment.logo.url}`}
                                        alt={data.establishment.Name}
                                        preview={{ src: `https://${SERVER_URL}${data.establishment.logo.url}` }}
                                    />
                                ) : (
                                    <Image
                                        style={{
                                            position: "relative",
                                            zIndex: 10,
                                            objectFit: 'cover',
                                            width: '22vw',
                                            height: '22vw',
                                            borderRadius: "2%"
                                        }}
                                        src={`https://${SERVER_URL}/img/noimage.jpg`}
                                        alt={data.establishment.Name}
                                    />
                                )
                            }
                        ></Card>
                    </Col>
                    <Col span={16}>
                        <Card bordered={false}>
                            <Space direction="vertical" align="start">
                                <Input
                                    onChange={debounce((e) => updateEsta(e), waitin)}
                                    name="Name"
                                    className="restoDetailsTitle"
                                    bordered={false}
                                    defaultValue={data.establishment.Name}
                                />
                                <Input
                                    onChange={debounce((e) => updateEsta(e), waitin)}
                                    name="address"
                                    defaultValue={data.establishment.address}
                                    bordered={false}
                                />

                                <Input
                                    onChange={debounce((e) => updateEsta(e), waitin)}
                                    name="zipcode"
                                    bordered={false}
                                    style={{ width: "15%" }}
                                    defaultValue={data.establishment.zipcode}
                                />
                                <Input
                                    onChange={debounce((e) => updateEsta(e), waitin)}
                                    name="city"
                                    bordered={false}
                                    style={{ width: "85%" }}
                                    defaultValue={data.establishment.city}
                                />
                                <Input
                                    onChange={debounce((e) => updateEsta(e), waitin)}
                                    name="Phone"
                                    bordered={false}
                                    className="restoDetailsPhone"
                                    addonBefore="Téléphone"
                                    defaultValue={data.establishment.Phone}
                                />
                                <TextArea
                                    rows={2}
                                    onChange={debounce((e) => updateEsta(e), waitin)}
                                    name="Description"
                                    bordered={false}
                                    className="restoDetailsDescription"
                                    defaultValue={data.establishment.Description}
                                />
                            </Space>
                        </Card>
                    </Col>
                </Row>

                <Space direction="vertical" style={{ width: "100%" }}>

                    <GestionLogo esta={data.establishment} />

                    <Card loading={loading} style={{
                        padding: "1em 0", 
                        margin: "0", 
                        width: "100%" 
                    }}>

                        <Owner resto={data.establishment} />

                        <Input
                            placeholder="https://twitter.com/"
                            onChange={debounce((e) => updateEsta(e), waitin)}
                            bordered={false}
                            className="restoDetailsSocials"
                            addonBefore="twitter"
                            name="twitter"
                            defaultValue={data.establishment.twitter}
                        />
                        <Input
                            placeholder="https://www.facebook.com/"
                            onChange={debounce((e) => updateEsta(e), waitin)}
                            bordered={false}
                            className="restoDetailsSocials"
                            addonBefore="facebook"
                            name="facebook"
                            defaultValue={data.establishment.facebook}
                        />
                        <Input
                            placeholder="https://www.instagram.com/"
                            onChange={debounce((e) => updateEsta(e), waitin)}
                            bordered={false}
                            className="restoDetailsSocials"
                            addonBefore="instagram"
                            name="instagram"
                            defaultValue={data.establishment.instagram}
                        />
                    </Card>
                    
                    <Card style={{
                        padding: "1.2em 0",
                        margin: "0",
                        width: "100%"
                    }}>
                        <p>cliquez sur le QRcode <br />pour le télécharger</p>
                                <QRCode
                                    id='leQr'
                            value={
                                establishment
                                    ? `https://user.prabel.pro/${establishment.id}`
                                    : 'Safe Menus'
                            }
                                    size={300}
                                    bgColor={"#ffffff"}
                                    fgColor={"#2ec2ca"}
                                    level={"Q"}
                                    includeMargin={false}
                                    renderAs={"canvas"}
                                    imageSettings={{
                                        src: `${logo}`,
                                        x: null,
                                        y: null,
                                        width: 50  ,
                                        height: 50,
                                        excavate: true,
                                    }}
                                    onClick={() => downloadQR(establishment ? establishment.Name : 'Safe Menus')}
                                    style={{ cursor: "pointer" }}
                        />

                    </Card>
                    <Card style={{
                        padding: "1.2em 0",
                        margin: "0",
                        width: "100%"
                    }}>
                        {user.role.id === "3"
                            ? (<Popconfirm title="Supprimer ce restaurant ?" onConfirm={() => deleteEstablishment({ variables: { id: id } })}><Button
                                className="in-esta-00"
                                type="primary"
                                danger
                            >
                                Supprimer le restaurant
                            </Button></Popconfirm>)
                            : null
                        }
                    </Card>
                </Space>
            </div>
        </form>
    );
};

export default Establishment;
