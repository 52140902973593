export const allergenes = [
    {
        fr: {
            Allergènes: [
                "Crustacés",
                "Oeufs",
                "Poissons",
                "Arachides",
                "Soja",
                "Lait",
                "Fruit à coques",
                "Céleri",
                "Moutarde",
                "Graines de sésame",
                "Anhydride sulfureux et sulfites",
                "Lupin",
                "Mollusques",
            ],
        },
    },
    {
        en: {
            Allergens: [
                "Crustaceans",
                "Eggs",
                "Fish",
                "Peanuts",
                "Soy",
                "Milk",
                "Nuts",
                "Celery",
                "Mustard",
                "Sesame Seeds",
                "Sulphur dioxide and sulphites",
                "Lupin",
                "Molluscs",
            ],
        },
    },
    {
        ko: {
            알레르겐: [
                "갑각류",
                "계란",
                "생선",
                "땅콩",
                "콩",
                "우유",
                "견과류",
                "셀러리",
                "겨자",
                "참깨",
                "이산화 황과 황산염",
                "루핀",
                "연체 동물",
            ],
        },
    },
    {
        de: {
            Allergene: [
                "Krebstiere",
                "Eier",
                "Fische",
                "Erdnüsse",
                "Soja",
                "Milch",
                "Schalenfrüchte",
                "Sellerie",
                "Senf",
                "Sesamsamen",
                "Schwefeldioxid und Sulfite",
                "Lupin",
                "Weichtiere",
            ],
        },
    },
    {
        et: {
            Allergeenid: [
                "Koorikloomad",
                "Munad",
                "Kala",
                "Maapähklid",
                "Soja",
                "Piim",
                "Pähklid",
                "Seller",
                "Sinep",
                "Seesamiseemned",
                "Vääveldioksiid ja sulfitid",
                "Lupin",
                "Molluskid",
            ],
        },
    },
    {
        'zh-Hans': {
            过敏原: [
                "甲壳类动物",
                "鸡蛋",
                "鱼",
                "花生",
                "大豆",
                "牛奶",
                "坚果",
                "芹菜",
                "芥末",
                "芝麻种子",
                "二氧化硫和亚硫酸盐",
                "卢平",
                "莫卢斯",
            ],
        },
    },
    {
        ru: {
            Аллергены: [
                "Ракообразные",
                "Яйца",
                "Рыба",
                "Арахис",
                "Соя",
                "Молоко",
                "Орехи",
                "Сельдерей",
                "Горчица",
                "Семена кунжута",
                "Диоксид серы и сульфиты",
                "Люпин",
                "Моллюски",
            ],
        },
    },
    {
        it: {
            Allergeni: [
                "Crostacei",
                "Uova",
                "Pesce",
                "Arachidi",
                "Soia",
                "Latte",
                "Noci",
                "Sedano",
                "Senape",
                "Semi di Sesamo",
                "Anidride solforosa e solfiti",
                "Lupin",
                "Molluschi",
            ],
        },
    },
    {
        'pt-pt': {
            Alergénios: [
                "Crustáceos",
                "Ovos",
                "Peixe",
                "Amendoim",
                "Soja",
                "Leite",
                "Nozes",
                "Aipo",
                "Mostarda",
                "Sementes de Sésamo",
                "Dióxido de Enxofre e sulfitos",
                "Lupin",
                "Moluscos",
            ],
        },
    },
    {
        es: {
            Alérgenos: [
                "Crustáceos",
                "Huevos",
                "Pescado",
                "Cacahuetes",
                "Soja",
                "Leche",
                "Nueces",
                "Apio",
                "Mostaza",
                "Semillas de Sésamo",
                "Dióxido de azufre y sulfitos",
                "Lupin",
                "Moluscs",
            ],
        },
    },
];
