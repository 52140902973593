import React, { useContext } from "react";
import { EstablishmentsContext } from "../../Contexts/EstablishmentsContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { UsersContext } from "../../Contexts/UsersContext";
import { TreeSelect, notification, Empty } from "antd";
import { useMutation } from "@apollo/client";
import { UPDATE_ESTABLISHMENT_USER } from "../Establishments/gql_Establishments";
import { UPDATE_USER } from "../Users/gql_Users";

const { TreeNode } = TreeSelect;

const UserEstablishment = ({ id, tu }) => {

    const notify = (type, what) => {
        notification[type]({
            message: 'mise à jour réussie',
            description: what,
        });
    };

    const { establishments, handleSetEstablishments } = useContext(EstablishmentsContext);
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext);
    const { users, handleSetUsers } = useContext(UsersContext);

    const refreshAll = (data) => {
        const udpdatedUsers = users.map(user => user.id !== data.updateUser.user.id ? user : data.updateUser.user);
        handleSetUsers(udpdatedUsers);
        notify(
            'success',
            `Assignation des restaurants mise à jours`
        );
    }

    const [updateUser, { error: error_uu, data: data_uu }] = useMutation(UPDATE_USER, {
        onCompleted: (data_uu) => refreshAll(data_uu),
        onError: error_uu => console.log(":: UPDATE_USER", JSON.stringify(error_uu)),
    });

    const [updateEstablishmentUser, { error: error_ueu, data: data_ueu }] = useMutation(UPDATE_ESTABLISHMENT_USER, {
        onCompleted: (data_ueu) => console.log("EU UPDATED!!!!!!", data_ueu),
        onError: error_ueu => console.log(":: UPDATE_ESTABLISHMENT_USER", JSON.stringify(error_ueu)),
    });


    const getUserEstablishment = (userEstaID) => {
        // console.log("establishments :: ", establishments)
        if (userEstaID === null)
            return
        let establishmentIs;
        Object.values(establishments).map((esta) => {
            if (userEstaID === esta.id)
                establishmentIs = esta.Name
        });
        return establishmentIs;
    }

    const removeUserEstablishment = (value) => {
        if (!value)
            return
        let estaToUpdate;
        let updatedRow = {
            id: tu.id,
            email: tu.email,
            username: tu.email,
            lastname: tu.lastname,
            firstname: tu.firstname,
            establishments: [],
        };
        updateUser({ variables: updatedRow });
        const udpdatedEstablishments = establishments.map(esta => esta.id !== value
            ? (esta)
            : ({ ...esta, user: null }, estaToUpdate = { ...esta, user: null })
        )
        handleSetEstablishments(udpdatedEstablishments);
        handleSetEstablishment(estaToUpdate);
    }

    const assignUserEstablishment = (value) => {
        if (!value)
            return
        let estaToUpdate;
        let updatedRow = {
            id: tu.id,
            email: tu.email,
            username: tu.email,
            lastname: tu.lastname,
            firstname: tu.firstname,
            establishments: [value],
        };
        updateUser({ variables: updatedRow });
        const udpdatedEstablishments = establishments.map(esta => esta.id !== value
            ? (esta)
            : ({ ...esta, user: { id: tu.id, __typename: "UsersPermissionsUser" } }, estaToUpdate = { ...esta, user: { id: tu.id, __typename: "UsersPermissionsUser" } })
        );
        handleSetEstablishments(udpdatedEstablishments);
        handleSetEstablishment(estaToUpdate);
    }

    return (
        <TreeSelect
            value={getUserEstablishment(id)}
            dropdownMatchSelectWidth={2}
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            notFoundContent={<Empty imageStyle={{ display: "none" }} style={{ paddingBottom: "10px" }} description={`Supprimer l'ancien restaurant`} />}
            placeholder="Attribuer"
            allowClear
            treeDefaultExpandAll
            filterTreeNode={true}
            onClear={() => removeUserEstablishment(id)}
            onChange={(value) => assignUserEstablishment(value)}
        >
            {tu.establishments.length === 0 && Object.values(establishments).map((esta) => {

                return <TreeNode
                    key={esta.id}
                    value={esta.id}
                    title={esta.Name}
                    disabled={esta.user}
                />

            })}

        </TreeSelect>
    )
}

export default UserEstablishment;