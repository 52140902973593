import React, { useState, useContext, useEffect } from "react";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { PageHeader, Button, Tabs, Input, Empty, Space, Row, Col, notification } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { GET_CATEGORIES, CREATE_CATEGORY, UPDATE_CATEGORY, DELETE_CATEGORY } from "./gql_Categories";
import { CREATE_DISH, UPDATE_DISH } from "./gql_Dishes";
import { useLazyQuery, useMutation } from "@apollo/client";
import { formatString } from "../../constants"
import Dish from "./Dish";

const { TabPane } = Tabs;
const { Search } = Input;

const Dishes = () => {

    const [esta, setEsta] = useState('')
    const [activeKey, setActiveKey] = useState('');
    const [inputIsHidden, setInputIsHidden] = useState(true);
    const [input2IsHidden, setInput2IsHidden] = useState(true);
    const [inEditCat, setInEditCat] = useState('');
    const [inEditCatID, setInEditCatID] = useState('');
    const [allDishesAreVisible, setAllDishesAreVisible] = useState(false)
    const [panes, setPanes] = useState([
        { title: '...', content: 'chargement en cours...', key: '1' },
    ]);

    const { general, handleSetGeneral, showMenu, menuVisible } = useContext(GeneralContext)
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext)

    const [createDish, { error: error_cd, data: data_cd }] = useMutation(CREATE_DISH, {
        onError: error_cd => console.log("error_cd :: ", JSON.stringify(error_cd)),
        onCompleted: (data_cd) => categories({ variables: { establishment: establishment.id } }),
        fetchPolicy: 'no-cache',
    });

    const [categories, { error: error_gc, data: data_gc }] = useLazyQuery(GET_CATEGORIES, {
        onError: error_gc => console.log("error_gc", JSON.stringify(error_gc)),
        onCompleted: data_gc => generatePanesFromData(data_gc),
        fetchPolicy: "no-cache",
    });

    const [createCategory, { error: error_cc, data: data_cc }] = useMutation(CREATE_CATEGORY, {
        onError: error_cc => console.log("error_cc", JSON.stringify(error_cc)),
        onCompleted: data_cc => generateNewTab(data_cc)
    });

    const [updateCategory, { error: error_uc, data: data_uc }] = useMutation(UPDATE_CATEGORY, {
        onError: error_uc => console.log("error_uc", JSON.stringify(error_uc)),
        onCompleted: data_uc => categories({ variables: { establishment: establishment.id } }),
    });

    const [deleteCategory, { error: error_dc, data: data_dc }] = useMutation(DELETE_CATEGORY, {
        onError: error_dc => console.log("error_dc", JSON.stringify(error_dc)),
        onCompleted: data_dc => categories({ variables: { establishment: establishment.id } }),
        fetchPolicy: 'no-cache',
    });

    const [updateDish, { error_ud, data_ud }] = useMutation(UPDATE_DISH, {
        onError_ud: error_ud => console.log("error_ud :: ", JSON.stringify(error_ud)),
        // onCompleted: data_ud => console.log("data_ud :: ", data_ud),
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        handleSetGeneral({ ...general, page: "/plats" });

        if (establishment) {
            categories({ variables: { establishment: establishment.id } });
            setEsta(`- ${establishment.Name} -`)
        }

        if (menuVisible === true) {
            showMenu()
        }

    }, []);

    const getPaneName = (panName) => {
        const lang = general.langue ? general.langue : 'fr';
        const tempName = Object.entries(JSON.parse(panName)).find((n, i) => {
            return lang === n[0]
        })
        return tempName[1]
    }

    useEffect(() => {
        if (data_gc) {
            generatePanesFromData(data_gc)
        }
    }, [general.langue]);

    const handleCatsOrder = (dir) => {

        const extractedCatWhere = panes.map(p => p.key)
        let valInit = extractedCatWhere.indexOf(inEditCatID)
        let newPanes
        const changeValuePosition = (arr, init, target) => {
            [arr[init], arr[target]] = [arr[target], arr[init]]
            return arr
        }
        if (dir === "r") {
            const valTarget = valInit + 1 < panes.length ? valInit + 1 : 0
            newPanes = changeValuePosition(panes, valInit, valTarget)
        } else if (dir === "l") {
            const valTarget = valInit - 1 > 0 ? valInit - 1 : panes.length - 1
            newPanes = changeValuePosition(panes, valInit, valTarget)
        }

        setPanes([...newPanes])

        newPanes.forEach((pan, i) => {
            updateCategory({ variables: { id: pan.key, rank: JSON.stringify(i) } })
        })

    }

    const generatePanesFromData = (datas) => {
        const newPanes = datas.categories.map((pane) => {
            return {
                rank: JSON.parse(pane.rank),
                title: getPaneName(pane.name),
                content: pane.dishes.map((dish, i) =>
                    <Dish
                        categories={categories}
                        cat={pane.id}
                        cats={datas.categories}
                        desc={dish}
                        key={i}
                    />),
                key: pane.id,
            }
        })

        newPanes.sort((a, b) => a.rank - b.rank)

        setPanes([...newPanes])

        if (!activeKey) {
            if (newPanes.length > 0) {
                setActiveKey(newPanes[0].key);
            }
            
        }
    }



    const handleToggleShowAll = () => {
        if (data_gc) {
            let newDatas = {}
            newDatas.categories = Object.values(data_gc.categories).map((c) => {
                const newDishes = c.dishes.map(d => {
                    updateDish({ variables: { id: d.id, folded: null } })
                    return { ...d, folded: null }
                })
                return { ...c, dishes: newDishes }
            })
            generatePanesFromData(newDatas)
        }
    }

    const handleCreateDish = () => {
        console.log("CREATE_DISH");
        const dishToCreate = {
            name: "Nouveau_Plat",
            active: true,
            folded: null,
            rank: '',
            establishment: establishment.id,
            categories: activeKey,
        }
        createDish({
            variables: dishToCreate,
        });
    }

    const handleOnChange = e => {
        setActiveKey(e);
    };



    const handleAddCat = (x) => {
        console.log("panes :: ", panes.length)
        createCategory({
            variables: {
                name: formatString(x),
                rank: JSON.stringify(panes.length + 1),
                description: `Contenu de ${x}`,
                establishment: establishment.id
            },
        });

    };

    const handleOnEdit = (targetKey) => {
        if (!targetKey.target) {
            startUpdateCat(targetKey)
        } else {
            if (inputIsHidden && input2IsHidden) {
                setInputIsHidden(false)
                if (general.langue !== 'fr') {
                    handleSetGeneral({ ...general, langue: 'fr' })
                    notification.success({ message: `Langue : Français ` })
                }
            }
        }
    };

    const startUpdateCat = (e) => {
        if (general.langue !== 'fr') {
            handleSetGeneral({ ...general, langue: 'fr' })
            notification.success({ message: `Langue : Français ` })
        }
        const c = data_gc.categories.find((cat) => {
            return cat.id === e
        });
        const tempName = Object.entries(JSON.parse(c.name)).find((n, i) => {
            return "fr" === n[0]
        })
        setInEditCat(formatString(tempName[1]))
        setInEditCatID(c.id)
        setInput2IsHidden(false)
    }

    const endUpdateCat = () => {
        updateCategory({ variables: { id: inEditCatID, name: inEditCat } })
        setInput2IsHidden(true)
    }

    const generateNewTab = (datas) => {
        setPanes([...panes, {
            title: datas.createCategory.category.name,
            content: datas.createCategory.category.description,
            key: datas.createCategory.category.id
        }]);
        setInputIsHidden(true);
        setTimeout(() => {
            setActiveKey(datas.createCategory.category.id)
        }, 500);
        categories({ variables: { establishment: establishment.id } });
    }

    const handleRemove = (x) => {
        if (panes.length > 1) {
            let newActiveKey = activeKey;
            let lastIndex;
            panes.forEach((pane, i) => {
                if (pane.key === x) {
                    lastIndex = i - 1;
                }
            });
            const newPanes = panes.filter(pane => pane.key !== x);
            if (newPanes.length && newActiveKey === x) {
                if (lastIndex >= 0) {
                    newActiveKey = newPanes[lastIndex].key;
                } else {
                    newActiveKey = newPanes[0].key;
                }
            }
            setPanes([...newPanes]);
            setActiveKey(newActiveKey);
            deleteCategory({
                variables: {
                    id: x,
                },
            });
            setInput2IsHidden(true)
        }
    }

    const handleStop = () => {
        setInputIsHidden(true)
        setInput2IsHidden(true)
    }

    if (!establishment) {
        return <Empty className="emptyty" description="sélectionner un restaurant" />;
    }

    return (
        <div style={{ backgroundColor: "white" }}>
            <PageHeader
                style={{ padding: "0 0 24px 0" }}
                className="site-page-header"
                title="Les Plats"
                subTitle="Liste"
            />
            <Space style={{ marginBottom: 16 }}>
                <Button
                    onClick={() => handleCreateDish()}
                    type="primary" 
                >
                    Ajouter un plat
                </Button>

                <Button 
                type="primary" ghost
                onClick={() => handleToggleShowAll()}
                >
                    <MenuOutlined />
                </Button>

            </Space>

            {!inputIsHidden && input2IsHidden
                ? (
                    <Row className="in-dishes-00">
                        <Col span={18}>
                            <Search
                                placeholder="nouvelle catégorie"
                                allowClear
                                enterButton="Ajouter une catégorie"
                                style={{ marginBottom: 16 }}
                                onSearch={(e) => handleAddCat(e)}
                            />
                        </Col>
                        <Col span={6}>
                            <Button ghost type="link" className="buttsInDishCatsEdit" onClick={() => handleStop()}>Annuler</Button>
                        </Col>
                    </Row>
                )
                : null
            }

            {!input2IsHidden && inputIsHidden
                ? (<Row style={{ marginBottom: "16px" }} className="in-dishes-01">

                    <Col span={12} style={{ width: "100%" }}>
                        <Search
                            value={inEditCat}
                            onChange={(e) => setInEditCat(e.target.value)}
                            allowClear
                            onClear={() => handleStop()}
                            enterButton="Mettre à jour"
                            onSearch={() => endUpdateCat()}
                        />
                    </Col>
                    <Col span={12}>
                        <Button ghost type="link" className="buttsInDishCatsEdit" onClick={() => handleStop()}>Annuler</Button>

                        <Button ghost type="primary" className="buttsInDishCatsEdit" onClick={() => handleCatsOrder("l")}>{"<"}</Button>
                        <Button ghost type="primary" className="buttsInDishCatsEdit" onClick={() => handleCatsOrder("r")}>{">"}</Button>

                        <Button className="buttsInDishCatsEdit"
                            type="primary"
                            danger
                            onClick={() => handleRemove(inEditCatID)}
                        >
                            Supprimer
                        </Button>
                    </Col>
                </Row>)
                : null
            }

            <Tabs
                type="editable-card"
                onChange={(e) => handleOnChange(e)}
                activeKey={activeKey}
                onEdit={(e) => handleOnEdit(e)}
                animated={false}
            >
                {panes.map((pane) => (
                    <TabPane
                        tab={pane.title}
                        key={pane.key}
                        id={pane.key}
                        closable={pane.closable}
                    >
                        {pane.content}
                    </TabPane>
                ))}
            </Tabs>
            <div style={{
                color: "#888888",
                padding: "1em 0",
                margin: "0",
                width: "100%"
            }}
                align='center'>
                {esta}
            </div>
        </div>
        
    )
};

export default Dishes;
