import React, { useState, useContext, useEffect } from "react";
import { UsersContext } from "../../Contexts/UsersContext";
import { GeneralContext } from "../../Contexts/GeneralContext";
// import { Link } from "react-router-dom";
import {
    Button,
    Tooltip,
    Table,
    Input,
    InputNumber,
    Popconfirm,
    Form,
    Switch,
    PageHeader,
} from "antd";
import {
    CloseOutlined,
    CheckOutlined,
    EditFilled,
    SaveFilled,
    CloseCircleFilled,
} from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/client";
import {
    GET_USERS,
    UPDATE_USER,
    CREATE_USER,
    DELETE_USER
} from "../Users/gql_Users";

import UserEstablishment from "./UserEstablishment";


const UsersSettings = () => {

    const { users, handleSetUsers } = useContext(UsersContext);
    const { general, handleSetGeneral } = useContext(GeneralContext);

    const { loading, error, data } = useQuery(GET_USERS, {
        onCompleted: (data) => handleSetUsers(data.users),
    });

    useEffect(() => {
        handleSetGeneral({ ...general, page: '/userssettings' });
    }, [data]);

    const handleUsersContext = (d) => {
        if (!d) { return }
        if (d.updateUser) {
            const upUser = d.updateUser.user;
            const newUsers = users.map(user => user.id !== upUser.id ? user : upUser);
            handleSetUsers(newUsers);

        }
        else if (d.createUser) {
            // console.log('CREATE');
            handleSetUsers([...users, { ...d.createUser.user }]);
        } else {
            // console.log('...');
        }

    };

    const [createUser] = useMutation(CREATE_USER, {
        onCompleted: (data_cu) => handleUsersContext(data_cu),
        update: (cache, { data: { createUser } }) => {
            const dataUser = cache.readQuery({ query: GET_USERS });

            if (!dataUser)
                return

            // console.log("dataUser", dataUser);

            const newUser = {};
            newUser["__typename"] = "User";
            newUser["id"] = createUser.user.id;
            newUser["email"] = createUser.user.email;
            newUser["username"] = createUser.user.username;
            newUser["provider"] = createUser.user.provider;
            newUser["confirmed"] = createUser.user.confirmed;
            newUser["blocked"] = createUser.user.blocked;
            newUser["role"] = createUser.user.role;
            newUser["lastname"] = createUser.user.lastname;
            newUser["firstname"] = createUser.user.firstname;
            newUser["establishments"] = createUser.user.establishments;

            const newUs = dataUser.users.filter(function (us) {
                return us;
            });

            const idForUpdateAddedUser = createUser.user;

            const newData = {};

            newData["users"] = [...newUs, newUser];

            cache.writeQuery({
                query: GET_USERS,
                data: newData,
            });

            edit(idForUpdateAddedUser);

        },
    });

    const [updateUser] = useMutation(UPDATE_USER, {
        onCompleted: (data_uu) => handleUsersContext(data_uu),
    });

    const [deleteUser] = useMutation(DELETE_USER, {
        onCompleted: data_du => handleUsersContext(data_du),
        onError: error_du => console.log("DELETE_USER_ERROR :: ", JSON.stringify(error_du)),
        refetchQueries: [{
            query: GET_USERS,
            fetchPolicies: 'no-cache'
        }],
    }
    );

    const handleCreateUsers = () => {

        createUser({
            variables: {
                email: "* new *",
                username: "* new *",
                role: '1',
                provider: 'local',
                password: "safemenus06",
                resetPasswordToken: "",
                confirmationToken: "",
                confirmed: true,
                blocked: false,
                lastname: "* new *",
                firstname: "* new *",
                establishments: [],
            },
        });
    };

    const handleDeleteUser = (id) => {
        // console.log("deleteUser :: deleteUser :: ", id)
        deleteUser({
            variables: {id: id}
        })
    }

    const [form] = Form.useForm();

    const [editingKey, setEditingKey] = useState("");

    const isEditing = (record) => record.id === editingKey;

    const [activateState, setActivateState] = useState(false);

    const edit = (record) => {
        setActivateState(record.Activate);

        form.setFieldsValue({
            id: record.id,
            email: "",
            username: "",
            provider: "",
            password: "",
            resetPasswordToken: "",
            confirmationToken: "",
            confirmed: true,
            blocked: false,
            role: "",
            lastname: "",
            firstname: "",
            establishments: "",
            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const save = async (id) => {
        try {
            const row = await form.validateFields();

            let updatedRow = {};

            updatedRow = {
                id: id,
                email: row.email,
                username: row.email,
                lastname: row.lastname,
                firstname: row.firstname,
            };

            updateUser({
                variables: updatedRow,
            });

            setEditingKey("");
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const handleActivate = (record) => {
        let blockedState = !record.blocked;

        updateUser({
            variables: {
                id: record.id,
                email: record.email,
                username: record.email,
                lastname: record.lastname,
                firstname: record.firstname,
                blocked: blockedState,
            },

            optimisticResponse: {
                __typename: "Mutation",
                updateUser: {
                    id: data.id,
                    __typename: "User",
                    email: data.email,
                    username: data.email,
                    lastname: data.lastname,
                    firstname: data.firstname,
                    blocked: data.blockedState,
                },
            },
        });
    };

    const columns = [
        {
            title: "",
            dataIndex: "blocked",
            width: "10%",
            editable: false,
            render: (e, record) => (
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={() => handleActivate(record)}
                    defaultChecked={!record.blocked}
                />
            ),
        },
        {
            title: "Prénom",
            dataIndex: "firstname",
            width: "15%",
            editable: true,
            ellipsis: { rows: 2, expandable: true, symbol: "+" },
            render: (firstname) => (
                <Tooltip placement="topLeft" title={firstname}>
                        {firstname}
                </Tooltip>
            ),
        },
        {
            title: "Nom",
            dataIndex: "lastname",
            width: "15%",
            editable: true,
            ellipsis: { rows: 2, expandable: true, symbol: "+" },
            render: (lastname, record) => (
                <Tooltip placement="topLeft" title={lastname}>
                        {lastname}
                </Tooltip >
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
            width: "20%",
            editable: true,
            ellipsis: { rows: 2, expandable: true, symbol: "+" },
            render: (email) => (
                <Tooltip placement="topLeft" title={email}>
                    {email}
                </Tooltip>
            ),
        },
        {
            title: "Restaurant",
            dataIndex: ["establishments", ["0"], ["id"]],
            width: "20%",
            editable: false,
            render: (estaId, record) => (<UserEstablishment id={estaId} tu={record} />)
        },
        {
            title: "",
            dataIndex: "Modifier",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a
                            // href="javascript:;"
                            onClick={() => save(record.id)}
                            style={{ marginRight: 8 }}
                        >
                            <SaveFilled />
                        </a>
                        <Popconfirm title="Annuler la modification ?" onConfirm={cancel}>
                            <a
                            // href="javascript:;"
                            >
                                <CloseCircleFilled />
                            </a>
                        </Popconfirm>
                    </span>
                ) : (
                        <span>
                            <a disabled={editingKey !== ""} onClick={() => edit(record)}>
                                <EditFilled />
                            </a>
                            <Popconfirm title="Supprimer cet utilisateur ?" onConfirm={() => handleDeleteUser(record.id)}>
                                <a
                                // href="javascript:;"
                                >
                                    <CloseCircleFilled style={{ color: "#ce1226", margin: "0 0 0 10px"}} />
                                </a>
                            </Popconfirm>
                        </span>
                    );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "" ? "number" : "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :( <br />{JSON.stringify(error)}</p>;

    return (
        <div>
            <PageHeader
                style={{ padding: "0 0 24px 0" }}
                className="site-page-header"
                title="Utilisateurs"
                subTitle="Liste"
            />
            <Button
                onClick={() => handleCreateUsers()}
                type="primary"
                style={{
                    marginBottom: 16,
                }}
            >
                Ajouter un Utilisateur
            </Button>
            <Form form={form} component={false}>
                <Table
                    className="userTable"
                    dataSource={data.users}
                    loading={loading}
                    rowKey="id"
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: cancel,
                    }}
                />
            </Form>
        </div>
    );
};

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex} style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Merci d'entrer un ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};


export default UsersSettings;
