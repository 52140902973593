import { gql } from "@apollo/client";

export const GET_CARDS = gql`
    query cards($establishments: [ID!]) {
        cards(where: { establishments: $establishments }) {
            id
            establishments{
                id
            }
            name
            description
            active
            dishes {
                id
                name
                active
                img {
                    formats
                }
                price
                allergenes
                origine
                side_dish
                description
                Ingredients
                rank
                categories {
                    id
                    name
                    rank
                }
            }
            drinks {
                id
                name
                specs {
                    id
                    size
                    price
                    priceHH
                    designation
                }
                description
                active
                drink_types {
                    id
                    name
                }
            }
            wines {
                id
                name
                country
                year
                region
                designation
                producer
                description
                organic
                specs {
                    id
                    size
                    price
                    priceHH
                    designation
                }
                active
                wine_types {
                    id
                    name
                }
            }
        }
    }
`;

export const CREATE_CARD = gql`
    mutation createCard(
        $name: String
        $description: String
        $active: Boolean
        $dishes: [ID]
        $drinks: [ID]
        $wines: [ID]
        $establishments: [ID]
    ) {
        createCard(
            input: {
                data: {
                    name: $name
                    description: $description
                    active: $active
                    dishes: $dishes
                    drinks: $drinks
                    wines: $wines
                    establishments: $establishments
                }
            }
        ) {
            card {
                id
                name
                active
                description
            }
        }
    }
`;

export const UPDATE_CARD = gql`
    mutation updateCard(
            $id: ID! 
            $active: Boolean 
            $description: String 
            $name: String 
            $dishes: [ID]
            $drinks: [ID]
            $wines: [ID]
        ) {
        updateCard(
            input: {
                where: { id: $id }
                data: { 
                    active: $active 
                    description: $description 
                    name: $name 
                    dishes: $dishes 
                    drinks: $drinks 
                    wines: $wines 
                }
            }
        ) {
            card {
                id
                name
                active
                description
                drinks {
                    id
                    name
                    specs {
                        id
                        size
                        price
                        priceHH
                        designation
                    }
                    description
                    active
                    drink_types {
                        id
                        name
                    }
                }
                wines {
                    id
                    name
                    country
                    year
                    region
                    designation
                    producer
                    description
                    organic
                    specs {
                        id
                        size
                        price
                        priceHH
                        designation
                    }
                    active
                    wine_types {
                        id
                        name
                    }
                }
                dishes {
                    id
                    name
                    active
                    img {
                        formats
                    }
                    price
                    allergenes
                    origine
                    side_dish
                    description
                    Ingredients
                    rank
                    categories {
                        id
                        name
                        rank
                    }
                }
            }
        }
    }
`;

export const DELETE_CARD = gql`
    mutation deleteCard($id: ID!) {
        deleteCard(input: { where: { id: $id } }) {
            card {
                id
                name
            }
        }
    }
`;
