import { gql } from "@apollo/client";

export const DELETE_ESTABLISHMENT = gql`
    mutation deleteEstablishment($id: ID!) {
    deleteEstablishment(input: { where: { id: $id } }) {
    establishment {
        id
    }
  }
}
`;

export const CREATE_DRINK_TYPES = gql`
mutation createDrinkType($establishments: [ID!], $name: String){
  createDrinkType(input: { data: { establishments: $establishments, name: $name } }) {
    drinkType {
      id
      name
    }
  }
}
`;

export const CREATE_WINE_TYPES = gql`
mutation createWineType($establishments: [ID!], $name: String){
  createWineType(input: { data: { establishments: $establishments, name: $name } }) {
    wineType {
      id
      name
    }
  }
}
`;

export const GET_ESTABLISHMENT = gql`
  query($id: ID!) {
    establishment(id: $id) {
      id
      created_at
      updated_at
      Name
      Description
      Activate
      Phone
      facebook
      twitter
      instagram
      logo {
        id
        created_at
        updated_at
        name
        alternativeText
        caption
        width
        height
        formats
        size
        url
        previewUrl
      }
      address
      zipcode
      city
      user {
        id
      }

      menus {
        id
      }
    }
  }
`;

export const GET_ESTABLISHMENTS = gql`
  query {
    establishments(sort: "id:desc") {
      id
      created_at
      updated_at
      Name
      Description
      Activate
      Phone
      facebook
      twitter
      instagram
      logo {
        id
        url
      }
      address
      zipcode
      city
      user {
        id
      }
      cards {
        id
      }
      menus {
        id
      }
    }
  }
`;

// MUTATIONS

export const CREATE_ESTABLISHMENT = gql`
  mutation createEstablishment(
    $Name: String!
    $Description: String
    $Activate: Boolean!
    $Phone: String
    $facebook: String
    $twitter: String
    $instagram: String
    $address: String
    $zipcode: String
    $city: String
  ) {
    createEstablishment(
      input: {
        data: {
          Name: $Name
          Description: $Description
          Activate: $Activate
          Phone: $Phone
          facebook: $facebook
          twitter: $twitter
          instagram: $instagram
          address: $address
          zipcode: $zipcode
          city: $city
        }
      }
    ) {
      establishment {
        id
        Name
        Description
        Activate
        Phone
        facebook
        twitter
        instagram
        address
        zipcode
        city
      }
    }
  }
`;

export const UPDATE_ESTABLISHMENT = gql`
  mutation updateEstablishment(
    $id: ID!
    $Name: String
    $Description: String
    $Activate: Boolean
    $Phone: String
    $facebook: String
    $twitter: String
    $instagram: String
    $address: String
    $zipcode: String
    $city: String
  ) {
    updateEstablishment(
      input: {
        where: { id: $id }
        data: {
          Name: $Name
          Description: $Description
          Activate: $Activate
          Phone: $Phone
          facebook: $facebook
          twitter: $twitter
          instagram: $instagram
          address: $address
          zipcode: $zipcode
          city: $city
        }
      }
    ) {
      establishment {
        id
        Name
        Description
        Activate
        Phone
        facebook
        twitter
        instagram
        address
        zipcode
        city
      }
    }
  }
`;

// VISUEL

export const UPDATE_ESTABLISHMENT_LOGO = gql`
  mutation(
    $file: Upload!
    $refId: ID
    $ref: String
    $field: String
    $source: String
  ) {
    upload(
      file: $file
      refId: $refId
      ref: $ref
      field: $field
      source: $source
    ) {
      id
      created_at
      updated_at
      name
    }
  }
`;

export const DELETE_FILE = gql`
  mutation($id: ID!) {
    deleteFile(input: { where: { id: $id } }) {
      file {
        id
        name
      }
    }
  }
`;

// MUTATIONS DETAILS

export const UPDATE_ESTABLISHMENT_NAME = gql`
  mutation updateEstablishmentName($id: ID!, $Name: String!) {
    updateEstablishment(input: { where: { id: $id }, data: { Name: $Name } }) {
      establishment {
        id
        Name
      }
    }
  }
`;

export const UPDATE_ESTABLISHMENT_ADDRESS = gql`
  mutation updateEstablishmentAddress($id: ID!, $address: String!) {
    updateEstablishment(input: { where: { id: $id }, data: { address: $address } }) {
      establishment {
        id
        address
      }
    }
  }
`;

export const UPDATE_ESTABLISHMENT_ZIPCODE = gql`
  mutation updateEstablishmentZipcode($id: ID!, $zipcode: String!) {
    updateEstablishment(input: { where: { id: $id }, data: { zipcode: $zipcode } }) {
      establishment {
        id
        zipcode
      }
    }
  }
`;

export const UPDATE_ESTABLISHMENT_CITY = gql`
  mutation updateEstablishmentCity($id: ID!, $city: String!) {
    updateEstablishment(input: { where: { id: $id }, data: { city: $city } }) {
      establishment {
        id
        city
      }
    }
  }
`;

export const UPDATE_ESTABLISHMENT_PHONE = gql`
  mutation updateEstablishmentPhone($id: ID!, $Phone: String!) {
    updateEstablishment(input: { where: { id: $id }, data: { Phone: $Phone } }) {
      establishment {
        id
        Phone
      }
    }
  }
`;

export const UPDATE_ESTABLISHMENT_DESCRIPTION = gql`
  mutation updateEstablishmentDescription($id: ID!, $Description: String!) {
    updateEstablishment(input: { where: { id: $id }, data: { Description: $Description } }) {
      establishment {
        id
        Description
      }
    }
  }
`;

export const UPDATE_ESTABLISHMENT_USER = gql`
  mutation updateEstablishmentUser($id: ID!, $user: ID) {
    updateEstablishment(input: { where: { id: $id }, data: { user: $user } }) {
      establishment {
        id
        user {
            id
        }
      }
    }
  }
`;

export const UPDATE_ESTABLISHMENT_TWITTER = gql`
  mutation updateEstablishmentTwitter($id: ID!, $twitter: String!) {
    updateEstablishment(input: { where: { id: $id }, data: { twitter: $twitter } }) {
      establishment {
        id
        twitter
      }
    }
  }
`;

export const UPDATE_ESTABLISHMENT_FACEBOOK = gql`
  mutation updateEstablishmentFacebook($id: ID!, $facebook: String!) {
    updateEstablishment(input: { where: { id: $id }, data: { facebook: $facebook } }) {
      establishment {
        id
        facebook
      }
    }
  }
`;

export const UPDATE_ESTABLISHMENT_INSTAGRAM = gql`
  mutation updateEstablishmentInstagram($id: ID!, $instagram: String!) {
    updateEstablishment(input: { where: { id: $id }, data: { instagram: $instagram } }) {
      establishment {
        id
        instagram
      }
    }
  }
`;
