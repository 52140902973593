import React, { createContext, useEffect, useState } from "react";

const exist = () => {
    const localEstablishment = JSON.parse(localStorage.getItem('establishment'))
    if (localEstablishment !== null) {
        return localEstablishment
    } else {
        return undefined
    }
}

export const EstablishmentContext = createContext({
    establishment: {},
    setEstablishment: () => { },
});

export const EstablishmentProvider = ({ children }) => {

    const [establishment, setEstablishment] = useState(exist());

    const handleSetEstablishment = (establishment) => {
        setEstablishment(establishment);
        localStorage.removeItem('establishment');
        localStorage.setItem('establishment', JSON.stringify(establishment));
    }

    // useEffect(() => {
    //     const localEstablishment = localStorage.getItem('establishment');
    //     if (localEstablishment)
    //         var establishmentIsPresent = JSON.parse(localEstablishment);
    //     handleSetEstablishment(establishmentIsPresent);
    // }, []);

    // useEffect(() => {
    //     // console.log("EstablishmentContext establishment", establishment);
    // }, [establishment]);

    return (
        <EstablishmentContext.Provider value={{ establishment, handleSetEstablishment }}>
            {children}
        </EstablishmentContext.Provider>
    )
}