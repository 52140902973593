import React, { useContext, useState, useEffect, useCallback } from "react";
import { Card, Input, notification, Button, Image, Space, Select, Switch, Collapse, Row, Col } from "antd";
import { CloseOutlined, PlusSquareOutlined, CheckOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { useMutation } from "@apollo/client";
import { UPDATE_DRINK, DELETE_DRINK, CREATE_DRINK } from "./gql_Drinks";
import { CopyFilled, DeleteFilled, PlusCircleFilled } from "@ant-design/icons";

import { GeneralContext } from "../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";

import { formatString } from "../../constants";

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const Drink = ({ drink, types, type, typeID, getDrinkTypes }) => {

    const { general, handleSetGeneral } = useContext(GeneralContext);
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext)

    const [createDrink, { error: error_cd, data: data_cd }] = useMutation(CREATE_DRINK, {
        onError: (error_cd) => console.log("error_cd :: ", JSON.stringify(error_cd)),
        onCompleted: (data_cd) => getDrinkTypes({ variables: { establishments: types[0].establishments[0].id } }),
        fetchPolicy: "no-cache",
    });

    const [updateDrink, { error: error_ud, data: data_ud }] = useMutation(UPDATE_DRINK, {
        onError: (error_ud) => console.log("error_ud :: ", JSON.stringify(error_ud)),
        onCompleted: (data_ud) => (
            notify('success'),
            setTempDrinkTitle(undefined),
            setTempDrinkDescription(undefined),
            getDrinkTypes({ variables: { establishments: types[0].establishments[0].id } })
        ),
        fetchPolicy: "no-cache",
    });

    const [deleteDrink] = useMutation(DELETE_DRINK, {
        onError: (error_dd) => console.log("error_dd :: ", JSON.stringify(error_dd)),
        onCompleted: (data_dd) => {
            notify('success');
            return getDrinkTypes({ variables: { establishments: types[0].establishments[0].id } })
        },
        fetchPolicy: "no-cache",
    });

    const waitin = 1100;

    const notify = (type) => {
        notification[type]({
            message: "Boisson mise à jour",
        });
    };

    const changeType = (e) => {
        const drinkToUp = { id: drink.id, drink_types: e };
        delete drinkToUp.__typename;
        updateDrink({ variables: drinkToUp });
    };

    const actualType = () => {
        return type;
    };

    const handleUpdateDrink = (e) => {
        const drinkToUp = { ...drink, [e.target.name]: e.target.value };
        delete drinkToUp.__typename;
        drinkToUp.specs.map(s => delete s.__typename)
        updateDrink({ variables: drinkToUp });
    };

    const handleDeleteDrink = (e) => {
        deleteDrink({ variables: { id: e } });
        setTempDrinkTitle(undefined)
    };

    const handleDuplicateDrink = (e, at) => {
        const whatType = types.filter((t) => t.name === at);
        const drinkToCreate = { ...drink, drink_types: whatType[0].id };
        delete drinkToCreate.__typename;
        delete drinkToCreate.id;
        drinkToCreate.specs.map((s) => {
            delete s.id;
            delete s.__typename;
        })
        console.log("drinkToCreate :: ", drinkToCreate)
        createDrink({ variables: drinkToCreate });
    };

    const contenants = ["Tasse", "Verre", "Bouteille", "Pichet", "Magnum", "Canette"];

    const [tempDrinkTitle, setTempDrinkTitle] = useState()

    const debouncedUpdate = useCallback(
        debounce((z) => {
            updateDrink({ variables: z })
        }, waitin),
        [],
    );

    const handleUpdateDrinkName = (e) => { //////////////// LA ////

        console.log(">> :: ", formatString(e.target.value))

        setTempDrinkTitle(formatString(e.target.value))

        const zavars = {
            id: drink.id,
            name: formatString(e.target.value),
            specs: [...drink.specs]
        }
        zavars.specs.map((s) => {
            delete s.__typename
        })


        debouncedUpdate(zavars)
    }

    useEffect(() => {
        // setTempDrinkTitle(drink.name)
        // console.log(":: useEffect ::")
    }, []);

    const drinkTitle = () => {
        return (
            <Input
                className="drinkTitle"
                onChange={(e) => handleUpdateDrinkName(e)}
                placeholder="Nom de la boisson"
                bordered={false}
                name="name"
                value={tempDrinkTitle ? tempDrinkTitle : drink.name}
                style={{ margin: "16px 0 10px 10px" }}
            />
        );
    };

    const updateContenantSpec = (e, id) => {
        const newSpecs = drink.specs.map((s) => {
            delete s.__typename
            return s.id === id ? (s.designation = e, s) : s
        })
        const newDrink = {
            ...drink,
            specs: newSpecs
        }
        updateDrink({
            variables: newDrink
        })
    };

    const updateSpecs = (e, id) => {
        const newSpecs = drink.specs.map((s) => {
            delete s.__typename
            // return s.id === id ? (s[e.target.name] = e.target.value, s) : s
            return s.id === id ? (s[e.target.name] = parseFloat([e.target.value].toString().replace(',', '.')), s) : s
        })
        const newDrink = {
            ...drink,
            specs: newSpecs
        }
        console.log("newDrink :: ", newDrink)
        updateDrink({
            variables: newDrink
        })
    };

    const handleAddSpecs = () => {
        drink.specs.map((s) => {
            delete s.__typename
        })
        const newDrink = {
            ...drink,
            specs: [...drink.specs, {
                size: 25,
                price: 10,
                priceHH: 5,
                designation: 'Verre',
            }]
        }
        updateDrink({
            variables: newDrink
        })
    }

    const handleDeleteSpec = (e) => {
        drink.specs.map((s) => {
            delete s.__typename
        })
        const newSpecs = drink.specs.filter(s => s.id !== e)
        const newDrink = { ...drink, specs: newSpecs }
        updateDrink({
            variables: newDrink
        })
    }

    const drinkSpecs = () => {
        return drink.specs.map((d, i) => {
            return (
                <Space style={{ width: "90%", margin: "0 0 2px 10px" }} key={i}>
                    <Select
                        defaultValue={d.designation}
                        onChange={debounce((e) => updateContenantSpec(e, d.id), waitin)}
                        name="designation"
                        size="small"
                        style={{ width: "100px" }}>
                        {contenants.map((c, i) => {
                            return (
                                <Option key={i} name="designation" value={c}>
                                    {c}
                                </Option>
                            );
                        })}
                    </Select>
                    <Input
                        defaultValue={d.size !== null ? `${d.size} cl` : undefined}
                        placeholder="cl"
                        onChange={debounce((e) => updateSpecs(e, d.id), waitin)}
                        name="size"
                        bordered={false}
                        style={{
                            textAlign: "center",
                            fontWeight: "500",
                            borderRight: "1px solid #eee",
                            borderRadius: "0",
                        }}
                    />
                    <Input
                        defaultValue={d.price !== null ? `${d.price} €` : undefined}
                        placeholder="Prix"
                        onChange={debounce((e) => updateSpecs(e, d.id), waitin)}
                        name="price"
                        bordered={false}
                        style={{
                            textAlign: "center",
                            fontWeight: "500",
                            borderRight: "1px solid #eee",
                            borderRadius: "0",
                        }}
                    />
                    <Input
                        defaultValue={d.priceHH !== null ? `${d.priceHH} €` : undefined}
                        placeholder="Prix -"
                        onChange={debounce((e) => updateSpecs(e, d.id), waitin)}
                        name="priceHH"
                        bordered={false}
                        style={{ textAlign: "center" }}
                    />
                    {drink.specs.length > 1
                        ? (<Button type="link" onClick={() => handleDeleteSpec(d.id)}>
                            <CloseOutlined />
                        </Button>)
                        : null
                    }

                </Space>
            );
        });
    };

    const [tempDrinkDescription, setTempDrinkDescription] = useState()

    const handleUpdateDrinkDescription = (e) => { //////////////// LA ////

        console.log(">> :: ", formatString(e.target.value))

        setTempDrinkDescription(formatString(e.target.value))

        const zavars = {
            id: drink.id,
            description: formatString(e.target.value),
            specs: [...drink.specs]
        }
        zavars.specs.map((s) => {
            delete s.__typename
        })

        debouncedUpdate(zavars)

    }

    const drinkDescription = () => {
        return (
            <TextArea
                placeholder="décrivez votre boisson..."
                onChange={(e) => handleUpdateDrinkDescription(e)}
                name="description"
                bordered={false}
                className="restoDetailsDescription"
                defaultValue={tempDrinkDescription ? tempDrinkDescription : drink.description}
                style={{ width: "100%" }}
            />
        );
    };

    const getElName = (el) => {
        return el.name;
    };

    const handleActivate = () => {
        console.log("handleActivate :: ")
        drink.specs.map((s) => {
            delete s.__typename
        })
        const zavars = {
            id: drink.id,
            active: !drink.active,
            specs: [...drink.specs]
        }
        updateDrink({ variables: zavars })
    }

    const active = () => {
        return (
            <Switch
                size='small'
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(e) => handleActivate(e)}
                defaultChecked={drink.active}
            />
        )
    }

    const handleCreateDrink = () => {
        const newDrink = {
            name: 'boisson',
            description: '',
            drink_types: typeID,
            specs: [{
                designation: "Verre",
                size: 8,
                price: 10,
                priceHH: 5
            }]
        }
        // console.log("newDrink :: ", newDrink)
        createDrink({ variables: newDrink })
    }

    /////////////////////////////////////////////////////////////// RETURN ////
    /////////////////////////////////////////////////////////////// RETURN ////

    return (
        <Card
            bordered={true}
            style={{ borderColor: "#2ec2ca", marginBottom: "1em" }}
            actions={[
                <Select
                    style={{ width: "80%" }}
                    placeholder="catégories"
                    onChange={(el) => changeType(el)}
                    defaultValue={actualType()}>
                    {types.map((el) => {
                        return <Option key={el.id}>{getElName(el)}</Option>;
                    })}
                </Select>,
                <CopyFilled onClick={() => handleDuplicateDrink(drink.id, actualType())} />,
                <PlusCircleFilled onClick={() => handleCreateDrink()}/>,
                <DeleteFilled onClick={() => handleDeleteDrink(drink.id)} />,
            ]}>
            <Space className="in-drink-01">
                {active()}{drinkTitle()}
                <Button type="link" onClick={() => handleAddSpecs()}>
                    <PlusSquareOutlined />
                </Button>
            </Space>
            <Space style={{ width: "100%", margin: "0 0 1em 0" }} className="in-drink-02">
                <Space direction="vertical">{drinkSpecs()}</Space>
                {drinkDescription()}
            </Space>
        </Card>
    );
};

export default Drink;
