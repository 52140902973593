import React, { useContext, useState, useEffect, useMemo } from "react";
import { Card, Input, notification, Image, Select, Switch, Collapse, Row, Col } from "antd";
import { debounce } from "lodash";
import { useMutation } from "@apollo/client";
import { UPDATE_DISH, DELETE_DISH, CREATE_DISH } from "./gql_Dishes";
import { CopyFilled, DeleteFilled, PlusCircleFilled } from "@ant-design/icons";
import { allergenes } from "./Allergenes";
import GestionVisuel from "./GestionVisuel"
import { GeneralContext } from "../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { SERVER_URL, formatString } from "../../constants";


const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const Dish = ({ desc, cats, cat, categories }) => {

    const { general, handleSetGeneral } = useContext(GeneralContext)
    const { establishment } = useContext(EstablishmentContext)

    const [createDish] = useMutation(CREATE_DISH, {
        onError: error_cd => console.log("error_cd :: ", JSON.stringify(error_cd)),
        onCompleted: () => categories({ variables: { establishment: cats[0].establishment.id } }),
        fetchPolicy: 'no-cache',
    });

    const [updateDish] = useMutation(UPDATE_DISH, {
        onError: error => console.log("error :: ", JSON.stringify(error)),
        onCompleted: () => categories({ variables: { establishment: cats[0].establishment.id } }),
        fetchPolicy: 'no-cache',
    });

    const [deleteDish] = useMutation(DELETE_DISH, {
        onError: error_dd => console.log("error_dd :: ", JSON.stringify(error_dd)),
        onCompleted: () => categories({ variables: { establishment: cats[0].establishment.id } }),
        fetchPolicy: 'no-cache',
    });

    const waitin = 1500;

    const notify = (type, what) => {
        notification[type]({
            message: 'Plat mis à jour',
            description: what,
        });
    };

    const handleUpdateDish = (e) => {
        console.log("e.target.name :: ", e.target.name)
        console.log("e.target.value :: ", e.target.value)
        if (e.target.name === "price") {
            const dishToUp = { ...desc, [e.target.name]: Number(e.target.value.replace(",",".")) }
            delete dishToUp.__typename;
            delete dishToUp.Ingredients;
            updateDish({ variables: dishToUp });
        } else {
            const dishToUp = { ...desc, [e.target.name]: formatString(e.target.value) }
            delete dishToUp.__typename;
            delete dishToUp.Ingredients;
            updateDish({ variables: dishToUp });
        }
        notify('success',
            e.target.name === "price" ? `${e.target.value} €` : e.target.value
        )
    }

    const handleUpdateDishDescription = (e) => { 
        const dishToUp = { ...desc, description: formatString(e.target.value) }
        delete dishToUp.__typename;
        delete dishToUp.Ingredients;
        updateDish({ variables: dishToUp });
        notify('success',
            e.target.value
        )
    }

    const changeCat = (e) => {
        const dishToUp = { ...desc, categories: e }
        delete dishToUp.__typename;
        delete dishToUp.Ingredients;
        updateDish({ variables: dishToUp });
    }

    const actualCat = () => {
        return cat;
    }

    const handleDeleteDish = (e, c) => {
        deleteDish({ variables: { id: e } })
    }

    const handleDuplicateDish = (e, ac) => {
        const [localIngredients, localIngredientID] = ingredientsDefinition()
        const dishToCreate = { ...desc, categories: ac, folded: null };
        delete dishToCreate.__typename;
        delete dishToCreate.id;
        delete dishToCreate.Ingredients;
        dishToCreate.Ingredients = localIngredients[0];
        console.log("dishToCreate :: ", dishToCreate);
        createDish({ variables: dishToCreate });
    }

    const handleCreateDish = () => {
        console.log("CREATE_DISH");
        const dishToCreate = {
            name: "Nouveau",
            active: true,
            folded: null,
            rank: '',
            establishment: establishment.id,
            categories: cat,
        }
        createDish({
            variables: dishToCreate,
        });
    }

    const handleActiveDish = (e) => {
        const dishToUp = { ...desc, active: e }
        delete dishToUp.__typename;
        delete dishToUp.Ingredients;
        updateDish({ variables: dishToUp });
    }

    const handleFoldedDish = (e) => {
        const dishToUp = { id: desc.id, folded: desc.folded === null ? desc.id : null }
        updateDish({ variables: dishToUp });
    }

    const handleAllergenes = (e) => {
        const dishToUp = { ...desc, allergenes: e.join() }
        delete dishToUp.__typename;
        delete dishToUp.Ingredients;
        updateDish({ variables: dishToUp });
    }

    /////////////////////////////////////// ELEMENTS ////
    /////////////////////////////////////// ELEMENTS ////

    const dishTitle = () => {
        return <Input
            className="dishTitle"
            onClick={e => e.stopPropagation()}
            onChange={debounce((e) => handleUpdateDish(e), waitin)}
            placeholder="Nom du plat"
            bordered={false}
            name='name'
            defaultValue={desc.name}
        />

    }

    const dishPrice = () => {
        return <Input
            className="dishPrice"
            onClick={e => e.stopPropagation()}
            onChange={debounce((e) => handleUpdateDish(e), waitin)}
            placeholder="Prix"
            bordered={false}
            name='price'
            addonAfter="€"
            defaultValue={desc.price}
        />
    }

    const ingredientsDefinition = () => {
        const lang = general.langue ? general.langue : 'fr';
        let presentIngredients = {};
        let localIngredients = [];
        let localIngredientID;
        if (desc.Ingredients) {
            presentIngredients = Object.entries(JSON.parse(desc.Ingredients));
            presentIngredients.map((ing, i) => {
                if (ing[0] === lang) {
                    localIngredientID = i
                    localIngredients.push(ing[1])
                }
            })
        }
        return [localIngredients, localIngredientID]
    }

    const [lesIngredients, setLesIngredients] = useState()

    const [inEdit, setInEdit] = useState(false)

    const handleSetInEdit = (e) => {
        setInEdit(true)
        if (general.langue !== 'fr') {
            handleSetGeneral({ ...general, langue: 'fr' })
            notification.success({ message: `Langue : Français ` })
        }
    }

    const handleUpdateIngredients = (e) => {
        setLesIngredients(e.target.value)
    }

    const handleOutedit = () => {
        const dishToUp = { ...desc, Ingredients: lesIngredients }
        delete dishToUp.__typename;
        updateDish({ variables: dishToUp })
        setInEdit(!inEdit)
    }

    const dishIngredients = () => {
        const [localIngredients, localIngredientID] = ingredientsDefinition()
        return <Input
            onFocus={(e) => handleSetInEdit(e)}
            onBlur={() => handleOutedit()}
            onChange={(e) => handleUpdateIngredients(e)}
            placeholder="Ingrédients"
            bordered={false}
            name='Ingredients'
            value={inEdit ? lesIngredients : localIngredients[0]}
        />
    }

    useEffect(() => {
        const [localIngredients, localIngredientID] = ingredientsDefinition()
        if (desc.Ingredients === null) {
            setLesIngredients(undefined)
            return
        }
        setLesIngredients(localIngredients[localIngredientID])
    }, []);

    const allergenesDefinition = () => {
        const lang = general.langue ? general.langue : 'fr';
        let titre;
        const lesAllergenes = [];
        let children = [];
        for (let key in Object.values(allergenes)) {
            lesAllergenes.push(Object.values(allergenes)[key]);
            lesAllergenes.forEach((el) => {
                if (Object.keys(el)[0] === lang) {
                    titre = Object.keys(Object.values(el)[0])[0];
                    const tousAllergenes = Object.values(Object.values(el)[0])[0];
                    children = [...tousAllergenes]
                }
            });
        }
        return [titre, children];
    }

    const dishAllergenes = () => {
        const [titre, children] = allergenesDefinition();
        const allChildren = children.map((child, i) => {
            return (<Option key={i} value={i} >{child}</Option>)
        })
        const getActualAllergenes = () => {
            return desc.allergenes && desc.allergenes.length > 0
                ? (desc.allergenes.split(",").map(al => parseInt(al)))
                : undefined
        }
        return <Select
            placeholder={titre}
            bordered={false}
            mode="tags"
            style={{ width: '100%', paddingLeft: "0" }}
            name="allergenes"
            onChange={(e) => handleAllergenes(e)}
            defaultValue={getActualAllergenes()}
        >
            {allChildren}
        </Select>
    }

    const origine = (e) => {
        return <Input
            addonBefore='Origine:'
            placeholder='... origine'
            bordered={false}
            name='origine'
            onChange={debounce((e) => handleUpdateDish(e), waitin)}
            defaultValue={desc.origine}
        />
    }

    const side_dish = (e) => {
        return <Input
            addonBefore='Accompagné par:'
            placeholder='... accompagnement'
            bordered={false}
            name='side_dish'
            onChange={debounce((e) => handleUpdateDish(e), waitin)}
            defaultValue={desc.side_dish}
        />
    }

    const dishDescription = () => {
        return <TextArea
            placeholder='... déscription de votre plat'
            onChange={debounce((e) => handleUpdateDishDescription(e), waitin)}
            name="description"
            bordered={false}
            className="restoDetailsDescription"
            defaultValue={desc.description}
        />
    }

    const getElName = (el) => {
        const lang = general.langue ? general.langue : 'fr';
        const tempName = Object.entries(JSON.parse(el)).find((n, i) => {
            return lang === n[0]
        })
        return tempName[1]
    }

    /////////////////////////////////////////////////////////////// RETURN ////
    /////////////////////////////////////////////////////////////// RETURN ////

    return (
        <Card
            bordered={true}
            actions={[
                <Select
                    style={{ width: "80%" }}
                    // placeholder="catégories"
                    onChange={(e) => changeCat(e)}
                    defaultValue={actualCat()}
                >
                    {cats.map((el) => {
                        return <Option key={el.id}>{getElName(el.name)}</Option>
                    })}
                </Select>,
                <CopyFilled onClick={() => handleDuplicateDish(desc.id, actualCat())} />,
                <PlusCircleFilled onClick={(e) => handleCreateDish(e)} />,
                <DeleteFilled onClick={() => handleDeleteDish(desc.id, cats[0].establishment.id)} />,
            ]}
            style={{ borderColor: "#2ec2ca", marginBottom: "1em" }}
        >
            <Collapse style={{ width: "100%" }} activeKey={desc.folded} onChange={(e) => handleFoldedDish(e)} ghost >
                <Panel key={JSON.parse(desc.id)} header={
                    <Row>
                        <Col span={12}>{dishTitle()}</Col>
                        <Col span={6}></Col>
                        <Col span={3} className="invisible"></Col>
                        <Col span={3}>{dishPrice()}</Col>
                    </Row>
                }>
                    <Row>
                        <Col span={8}>
                            <Card
                                bordered={false}
                                cover={
                                    desc.img !== null
                                        ? (
                                            <Image
                                                style={{
                                                    position: "relative",
                                                    zIndex: 10,
                                                    objectFit: 'cover',
                                                    width: '18vw',
                                                    height: '18vw',
                                                    borderRadius: "2%"
                                                }}
                                                src={`https://${SERVER_URL}${desc.img.url}`}
                                            alt={''}
                                                preview={{ src: `https://${SERVER_URL}${desc.img.url}` }}
                                        />
                                    ) : (
                                            <Image
                                                style={{
                                                    position: "relative",
                                                    zIndex: 10,
                                                    objectFit: 'cover',
                                                    width: '18vw',
                                                    height: '18vw',
                                                    borderRadius: "2%"
                                                }}
                                                src={`https://${SERVER_URL}/img/noimage.jpg`}
                                                alt={''}
                                            />
                                        )
                                }
                            ></Card>
                            
                            <GestionVisuel
                                dish={desc}
                                categories={categories}
                                cats={cats}
                                cat={cat}
                            />
                            
                        </Col>
                        <Col span={16}>
                            <div className="dish">
                                Ce plat est actuellement <Switch
                                    size="small"
                                    checkedChildren="disponible"
                                    unCheckedChildren="indisponible"
                                    defaultChecked={desc.active}
                                    onChange={(e) => handleActiveDish(e)}
                                />
                            </div>
                            <div className="dish">
                                {dishIngredients()}
                            </div>
                            <div className="dish">
                                {dishAllergenes()}
                            </div>
                            <div className="dish">
                                {origine()}
                            </div>
                            <div className="dish">
                                {side_dish()}
                            </div>
                            <div className="dish">
                                {dishDescription()}
                            </div>
                        </Col>
                    </Row>

                </Panel>
            </Collapse>
        </Card>
    )
}

export default Dish;