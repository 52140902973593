import React, { useState, useContext, useEffect } from "react";
import { GeneralContext } from "../../Contexts/GeneralContext";
import { EstablishmentContext } from "../../Contexts/EstablishmentContext";
import { PageHeader, Button, Tabs, Input, Empty, Space, Row, Col, notification } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {
    GET_DRINK_TYPES,
    CREATE_DRINK,
} from "./gql_Drinks";

import { useLazyQuery, useMutation } from "@apollo/client";
import Drink from "./Drink";

const { TabPane } = Tabs;
const { Search } = Input;

const Drinks = () => {

    const { general, handleSetGeneral, showMenu, menuVisible } = useContext(GeneralContext)
    const { establishment, handleSetEstablishment } = useContext(EstablishmentContext)

    const [esta, setEsta] = useState('')
    const [selecTab, setSelecTab] = useState('')
    const [activeKey, setActiveKey] = useState('');
    const [panes, setPanes] = useState([
        { title: '...', content: 'chargement en cours...', key: '1' },
    ]);

    const [getDrinkTypes] = useLazyQuery(GET_DRINK_TYPES, {
        onError: error_gd => console.log("error_gd", JSON.stringify(error_gd)),
        onCompleted: data_gd => generatePanesFromData(data_gd),
        fetchPolicy: "no-cache",
    });

    const [createDrink, { error: error_cd, data: data_cd }] = useMutation(CREATE_DRINK, {
        onError: (error_cd) => console.log("error_cd :: ", JSON.stringify(error_cd)),
        onCompleted: (data_cd) => {
            notify('success');
            return getDrinkTypes({ variables: { establishments: establishment.id } })
        },
        fetchPolicy: "no-cache",
    });

    const notify = (type) => {
        notification[type]({
            message: "Boisson mise à jour",
        });
    };

    useEffect(() => {
        handleSetGeneral({ ...general, page: "/boissons" });

        if (establishment) {
            getDrinkTypes({ variables: { establishments: establishment.id } });
            setEsta(`- ${establishment.Name} -`)
        }

        if (menuVisible === true) {
            showMenu()
        }

    }, []);

    const handleDrinkOrder = (source, datas, pane) => {
        source.sort((a, b) => (a.id > b.id) ? 1 : -1)
        return source.map((drink, i) => {
                return <Drink
                    drink={drink}
                    types={datas.drinkTypes}
                    type={pane.name}
                    typeID={pane.id}
                    getDrinkTypes={getDrinkTypes}
                    key={i}
                />
            })
    }

    const generatePanesFromData = (datas) => {
        const newPanes = datas.drinkTypes.map((pane) => {
            return {
                title: pane.name,
                content: pane.drinks.length > 0
                    ? handleDrinkOrder(pane.drinks, datas, pane)
                    : null
                ,
                key: pane.id,
            }

        })
        newPanes.sort((a, b) => (a.title > b.title) ? 1 : -1)
        setPanes(newPanes)

        if (!activeKey) {
            if (newPanes.length > 0) {
                setActiveKey(newPanes[0].key);
            }
        }
    }


    const handleChangeTab = (e) => {
        setActiveKey(e)
    }

    const handleCreateDrink = () => {
        const newDrink = {
            name: 'boisson',
            // description: '',
            drink_types: activeKey,
            specs: [{
                designation: "Verre",
                size: 8,
                price: 10,
                priceHH: 5
            }]
        }
        createDrink({ variables: newDrink })
    }

    if (!establishment) {
        return <Empty className="emptyty" description="sélectionner un restaurant" />;
    }

    return (
        <div style={{ backgroundColor: "white" }}>
            <PageHeader
                style={{ padding: "0 0 24px 0" }}
                className="site-page-header"
                title="Les Boissons"
                subTitle="De Votre Restaurant"
            />
            <Space style={{ marginBottom: 16 }}>
                <Button
                    onClick={() => handleCreateDrink()}
                    type="primary"
                    style={{}}
                >
                    Ajouter une boisson
                </Button>

            </Space>

            <Tabs
                type="card"
                animated={false}
                onChange={(e) => handleChangeTab(e)}
            >
                {panes.map((pane) => (
                    <TabPane
                        tab={pane.title}
                        key={pane.key}
                        id={pane.key}
                    >
                        {pane.content}
                    </TabPane>
                ))}
            </Tabs>
            <div style={{
                color: "#888888",
                padding: "1em 0",
                margin: "0",
                width: "100%"
            }}
                align='center'>
                {esta}
            </div>
        </div>
    )
};

export default Drinks;
