import React from "react";
import { Redirect, Route } from "react-router-dom";

import { AUTH_TOKEN } from "../../constants";
const authToken = localStorage.getItem(AUTH_TOKEN);


const PrivateRoute = ({ component: Component, ...rest }) => (
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /signin page
  <Route
    {...rest}
    render={(props) =>
      !authToken ? <Redirect to="/login" /> : <Component {...props} />
    }
  />
);

export default PrivateRoute;
