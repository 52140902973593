import { useState, useEffect } from "react"
import { Space, Select, Input, Button } from "antd"
import { CloseOutlined, PlusSquareOutlined, CheckOutlined } from "@ant-design/icons";

const { Option } = Select;

const WineSpecs = ({ specs, updateContenantSpec, updateSpecs, handleDeleteSpec, contenants }) => {

    const [specSize, setSpecSize] = useState([])
    const [specPrice, setSpecPrice] = useState([])
    const [specPriceHH, setSpecPriceHH] = useState([])

    const toUpdateSpecs = (e, id) => {
        // console.log("id :: ", e.target.name, e.target.value, id)
        if (e.target.name === "size") {
            setSpecSize([e.target.value.replace(" cl", ""), id])
        } else if (e.target.name === "price") {
            setSpecPrice([e.target.value.replace(" €", ""), id])
        } else if (e.target.name === "priceHH") {
            setSpecPriceHH([e.target.value.replace(" €", ""), id])
        }
        updateSpecs(e, id)
    }

    useEffect(() => {
        if (specs) {
            setSpecSize([specs.size, specs.id])
            setSpecPrice([specs.price, specs.id])
            setSpecPriceHH([specs.priceHH, specs.id])
        }
    }, []);


    return (
        <Space style={{ width: "90%", margin: "0 0 2px 10px" }}>
            <Select
                value={specs.designation}
                onChange={(e) => updateContenantSpec(e, specs.id)}
                name="designation"
                size="small"
                style={{ width: "100px" }}>
                {contenants.map((c, i) => {
                    return (
                        <Option key={i} name="designation" value={c}>
                            {c}
                        </Option>
                    );
                })}
            </Select>
            <Input
                value={`${specSize[0]} cl`}
                placeholder=".. cl"
                onChange={e => toUpdateSpecs(e, specs.id)}
                name="size"
                bordered={false}
                style={{
                    textAlign: "center",
                    fontWeight: "500",
                    borderRight: "1px solid #eee",
                    borderRadius: "0",
                }}
            />
            <Input
                value={`${specPrice[0]} €`}
                placeholder="Prix"
                onChange={e => toUpdateSpecs(e, specs.id)}
                name="price"
                bordered={false}
                style={{
                    textAlign: "center",
                    fontWeight: "500",
                    borderRight: "1px solid #eee",
                    borderRadius: "0",
                }}
            />
            <Input
                value={`${specPriceHH[0]} €`}
                placeholder="Prix -"
                onChange={e => toUpdateSpecs(e, specs.id)}
                name="priceHH"
                bordered={false}
                style={{ textAlign: "center" }}
            />
            
            <Button type="link" onClick={() => handleDeleteSpec(specs.id)}>
                <CloseOutlined />
            </Button>
              
            
        </Space>
    );

};

export default WineSpecs