import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USERS } from "./gql_Users";
import { Link } from "react-router-dom";


const Owner = ({ resto }) => {

    const { loading, error, data } = useQuery(GET_USERS, {
        onCompleted: (data) => actualUser(data),
    });

    let assigned = [];

    const [assignedUser, setAssignedUser] = useState("");
    const [linkToUser, setLinkToUser] = useState();

    const actualUser = (data) => {
        if (resto.user) {
            assigned = (data.users.filter(user => user.id === resto.user.id))
            if (assigned.length > 0) {
                setAssignedUser(`${assigned[0].firstname} ${assigned[0].lastname}`);
            }
        }
        else {
            setAssignedUser(`pas encore déterminé`)
        }
    }

    useEffect(() => {
        // console.log("UE DATA", data);
    }, [data]);

    if (loading) return <p>loading...</p>;
    if (error) return <p>Oups...{JSON.parse(error)}</p>;


    return (
        <div style={{padding:"0.5em 0"}}>
            Responsable : {<Link to="/settings">{assignedUser}</Link>}
        </div>
    );



};

export default Owner;
